import { Component, Vue } from 'vue-property-decorator';
import { RouteConfig } from 'vue-router';
import getLogger from '@/shared/logger/logger';

const LOG = getLogger('NavMixin');

type Dictionary<T> = { [key: string]: T };

@Component
export class NavigationMixin extends Vue {
  goTo(route: RouteConfig, params?: Dictionary<string>, eventToEmit?: string): void {
    if (this.$route.name !== route.name) {
      params
        ? this.$router
            .push({ name: route.name, params: params })
            .catch((error) => this.handleRoutingError(route.path, error))
        : this.$router.push({ name: route.name }).catch((error) => this.handleRoutingError(route.path, error));
    }
    if (eventToEmit) {
      this.$store.dispatch(eventToEmit);
    }
  }

  handleRoutingError(path: string, error: unknown) {
    LOG.error(`Routing to ${path} failed: ${error}`);
  }

  async replaceCurrentRouteWith(name: string) {
    await this.$router.replace({ name }).catch((err) => {
      LOG.warn(`Error while replacing current route: ${err}`);
    });
  }
}
