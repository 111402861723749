import { RouteConfig } from 'vue-router';
import { ROUTE_NAMES } from '@/ui/constant/routes.constants';
import CampaignsView from '@/ui/views/CampaignsView.vue';
import { USER_ROLE } from '@/core/user/enums/UserRole';
import { USER_PERMISSION } from '@/core/user/enums/UserPermission';

const PATHS: Record<string, string> = {
  CAMPAIGNS: '/campaigns',
  CAMPAIGN_DETAIL: '/campaign-detail/:campaignId',
  CAMPAIGN_DETAIL_MEDIA: '/campaign-detail/:campaignId/media',
  CAMPAIGN_DETAIL_STATISTICS: '/campaign-detail/:campaignId/statistics',
  CREATE_CAMPAIGN: '/create-campaign',
  EDIT_CAMPAIGN: '/edit-campaign',
};

export const ROUTE_CAMPAIGNS: RouteConfig = {
  path: PATHS.CAMPAIGNS,
  name: ROUTE_NAMES.CAMPAIGNS,
  component: CampaignsView,
  meta: {
    authorizedUserRoles: [USER_ROLE.SUPER_ADMIN, USER_ROLE.ADMIN, USER_ROLE.EDITOR, USER_ROLE.USER],
  },
};

export const ROUTE_CREATE_CAMPAIGN: RouteConfig = {
  path: PATHS.CREATE_CAMPAIGN,
  name: ROUTE_NAMES.CREATE_CAMPAIGN,
  component: () => import('@/ui/views/EditCampaign.vue'),
  meta: {
    authorizedUserRoles: [USER_ROLE.SUPER_ADMIN, USER_ROLE.ADMIN, USER_ROLE.EDITOR],
  },
};

export const ROUTE_EDIT_CAMPAIGN: RouteConfig = {
  path: PATHS.EDIT_CAMPAIGN,
  name: ROUTE_NAMES.EDIT_CAMPAIGN,
  component: () => import('@/ui/views/EditCampaign.vue'),
  meta: {
    authorizedUserRoles: [USER_ROLE.SUPER_ADMIN, USER_ROLE.ADMIN, USER_ROLE.EDITOR],
  },
};

const ROUTE_CAMPAIGN_DETAIL_MEDIA: RouteConfig = {
  path: PATHS.CAMPAIGN_DETAIL_MEDIA,
  name: ROUTE_NAMES.CAMPAIGN_DETAIL_MEDIA,
  component: () => import('@/ui/components/organisms/campaigns/CampaignDetailMedia.vue'),
  meta: {
    authorizedUserRoles: [USER_ROLE.SUPER_ADMIN, USER_ROLE.ADMIN, USER_ROLE.EDITOR, USER_ROLE.USER],
  },
};

const ROUTE_CAMPAIGN_DETAIL_STATISTIC: RouteConfig = {
  path: PATHS.CAMPAIGN_DETAIL_STATISTICS,
  name: ROUTE_NAMES.CAMPAIGN_DETAIL_STATISTICS,
  component: () => import('@/ui/components/organisms/campaigns/CampaignDetailStatistics.vue'),
  meta: {
    authorizedUserRoles: [USER_ROLE.SUPER_ADMIN, USER_ROLE.ADMIN, USER_ROLE.EDITOR, USER_ROLE.USER],
    requiredPermissions: [USER_PERMISSION.STATISTICS],
  },
};

export const ROUTE_CAMPAIGN_DETAIL: RouteConfig = {
  path: PATHS.CAMPAIGN_DETAIL,
  name: ROUTE_NAMES.CAMPAIGN_DETAIL,
  component: () => import('@/ui/views/CampaignDetailView.vue'),
  props: true,
  redirect: ROUTE_CAMPAIGN_DETAIL_MEDIA,
  meta: {
    authorizedUserRoles: [USER_ROLE.SUPER_ADMIN, USER_ROLE.ADMIN, USER_ROLE.EDITOR, USER_ROLE.USER],
  },
  children: [ROUTE_CAMPAIGN_DETAIL_MEDIA, ROUTE_CAMPAIGN_DETAIL_STATISTIC],
};
