import { container } from 'tsyringe';
import { StatisticsService } from '@/core/statistics/services/StatisticsService';
import type { CurrentUser } from '@/core/user/models/CurrentUser';
import { GeneralStatistic } from '@/core/statistics/models/Statistics';
import getLogger from '@/shared/logger/logger';
import { CancelToken } from 'axios';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const LOG = getLogger('GetGeneralStatisticUsecase');

export const getGeneralStatisticUsecase = async (
  currentUser: CurrentUser,
  customerId: string,
  startDate?: string,
  endDate?: string,
  zoneIds?: string[],
  cancelToken?: CancelToken,
): Promise<GeneralStatistic | undefined> => {
  const statisticsService = container.resolve<StatisticsService>('StatisticsService');
  try {
    return await statisticsService.getGeneralStatistic(
      currentUser,
      customerId,
      startDate,
      endDate,
      zoneIds,
      cancelToken,
    );
  } catch (e) {
    LOG.warn(`Could not get general statistic, ${e}`);
  }
};
