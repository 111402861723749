import i18n from '@/i18n';

export const getLastDayOfMonth = (date: string): string => {
  const newDate = new Date(date);
  return new Date(Date.UTC(newDate.getUTCFullYear(), newDate.getUTCMonth() + 1, 0)).toISOString();
};

export const getFirstDayOfMonth = (date: string): string => {
  const newDate = new Date(date);
  return new Date(Date.UTC(newDate.getFullYear(), newDate.getMonth(), 1)).toISOString();
};

export const formatDate = (date: string | undefined, dateType: string | undefined) => {
  if (date) {
    const newDate = new Date(date);
    const options =
      dateType === 'date'
        ? ({ day: 'numeric', month: 'short', year: 'numeric' } as const)
        : ({ month: 'short', year: 'numeric' } as const);
    return newDate.toLocaleDateString(i18n.i18next.language, options);
  }
  return '';
};

export const getStartDateStatisticsFilter = () => {
  const date = new Date();
  date.setMonth(date.getMonth() + 1);
  date.setFullYear(date.getFullYear() - 1);
  return date.toISOString();
};

export const getEndDateStatisticsFilter = () => {
  return new Date().toISOString();
};
