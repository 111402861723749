import { RouteConfig } from 'vue-router';
import { ROUTE_NAMES } from '@/ui/constant/routes.constants';
import { USER_ROLE } from '@/core/user/enums/UserRole';
import LoginView from '@/ui/views/LoginView.vue';
import { ROUTE_ADMIN } from '@/ui/router/admin.routes';
import {
  ROUTE_CAMPAIGN_DETAIL,
  ROUTE_CAMPAIGNS,
  ROUTE_CREATE_CAMPAIGN,
  ROUTE_EDIT_CAMPAIGN,
} from '@/ui/router/campaign.routes';
import { USER_PERMISSION } from '@/core/user/enums/UserPermission';

export const PATHS: Record<string, string> = {
  BASE: '/',
  LOGIN: '/login',
  CAMPAIGNS: '/campaigns',
  CREATE_CAMPAIGN: '/create-campaign',
  CUSTOMERS: '/customers',
  LABEL_ARCHIVE: '/label-archive',
  CAMPAIGN_DETAIL: '/campaign-detail/:campaignId',
  ADMIN_CREATE_CAMPAIGN_PATH: '/admin/create/campaign',
  STATISTICS: '/statistics',
  NOT_FOUND: '*',
};

export const ROUTE_BASE: RouteConfig = {
  path: PATHS.BASE,
  redirect: PATHS.CAMPAIGNS,
};

export const ROUTE_LOGIN: RouteConfig = {
  path: PATHS.LOGIN,
  name: ROUTE_NAMES.LOGIN,
  component: LoginView,
};

export const ROUTE_CUSTOMERS: RouteConfig = {
  path: PATHS.CUSTOMERS,
  name: ROUTE_NAMES.CUSTOMERS,
  component: () => import('@/ui/views/CustomerView.vue'),
  meta: {
    authorizedUserRoles: [USER_ROLE.SUPER_ADMIN],
  },
};

export const ROUTE_TAG_ARCHIVE: RouteConfig = {
  path: PATHS.LABEL_ARCHIVE,
  name: ROUTE_NAMES.TAG_ARCHIVE,
  component: () => import('@/ui/views/TagArchiveView.vue'),
  meta: {
    authorizedUserRoles: [USER_ROLE.SUPER_ADMIN, USER_ROLE.ADMIN, USER_ROLE.EDITOR],
  },
};

export const ROUTE_STATISTICS: RouteConfig = {
  path: PATHS.STATISTICS,
  name: ROUTE_NAMES.STATISTICS,
  component: () => import('@/ui/views/StatisticsView.vue'),
  meta: {
    authorizedUserRoles: [USER_ROLE.SUPER_ADMIN, USER_ROLE.ADMIN, USER_ROLE.EDITOR, USER_ROLE.USER],
    requiredPermissions: [USER_PERMISSION.STATISTICS],
  },
};

const NOT_FOUND: RouteConfig = {
  path: PATHS.NOT_FOUND,
  redirect: ROUTE_BASE,
};

export const ROUTES: RouteConfig[] = [
  ROUTE_LOGIN,
  ROUTE_BASE,
  ROUTE_ADMIN,
  ROUTE_CAMPAIGNS,
  ROUTE_CAMPAIGN_DETAIL,
  ROUTE_CREATE_CAMPAIGN,
  ROUTE_CUSTOMERS,
  ROUTE_EDIT_CAMPAIGN,
  ROUTE_TAG_ARCHIVE,
  ROUTE_STATISTICS,
  NOT_FOUND,
];
