export enum ROUTE_NAMES {
  ADMIN = 'AdminView',
  ADMIN_USERS = 'AdminViewUsers',
  ADMIN_CAMPAIGNS = 'AdminViewCampaigns',
  ADMIN_LABELS = 'AdminViewLabels',
  ADMIN_VERSION = 'AdminViewVersion',
  CAMPAIGNS = 'CampaignsView',
  CAMPAIGN_DETAIL = 'CampaignDetailView',
  CAMPAIGN_DETAIL_MEDIA = 'CampaignDetailViewMedia',
  CAMPAIGN_DETAIL_STATISTICS = 'CampaignDetailViewStatistics',
  CREATE_CAMPAIGN = 'CreateCampaign',
  CUSTOMERS = 'CustomersView',
  EDIT_CAMPAIGN = 'EditCampaign',
  LOGIN = 'LoginView',
  STATISTICS = 'StatisticsView',
  TAG_ARCHIVE = 'TagArchiveView',
}
