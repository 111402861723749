import { Module } from 'vuex';
import { RootState } from '@/ui/store';
import { GeneralStatistic, BaseStatistic } from '@/core/statistics/models/Statistics';
import { getGeneralStatisticUsecase } from '@/core/statistics/usecases/getGeneralStatisticsUsecase';
import { getCampaignStatisticsUseCase } from '@/core/statistics/usecases/getCampaignStatisticsUseCase';
import axios, { CancelTokenSource } from 'axios';
import { AlertStoreActions, AlertStoreMutations, AlertType } from '@/ui/stores/AlertStore';

export const StatisticStoreActions = {
  SET_GENERAL_STATISTIC: 'statisticStore/setGeneralStatistic',
  SET_CAMPAIGN_STATISTIC: 'statisticStore/setCampaignStatistic',
};

export const StatisticStoreMutations = {
  SET_START_DATE: 'statisticStore/setStartDate',
  SET_END_DATE: 'statisticStore/setEndDate',
  RESET_CAMPAIGN_STATISTIC: 'statisticStore/resetCampaignStatistic',
  RESET_DATES: 'statisticStore/resetDates',
};

export interface StatisticState {
  generalStatistic: GeneralStatistic | undefined;
  generalStatisticCancelTokenSource: CancelTokenSource | null;
  campaignStatistic: BaseStatistic | undefined;
  startDate: string | undefined;
  endDate: string | undefined;
}

export const statisticStore: Module<StatisticState, RootState> = {
  namespaced: true,
  state: {
    generalStatistic: undefined,
    generalStatisticCancelTokenSource: null,
    campaignStatistic: undefined,
    startDate: undefined,
    endDate: undefined,
  },
  mutations: {
    setStartDate(state, startDate: string) {
      state.startDate = startDate;
    },
    setEndDate(state, endDate: string) {
      state.endDate = endDate;
    },
    setGeneralStatistic(state, statistic: GeneralStatistic) {
      state.generalStatistic = statistic;
    },
    setGeneralStatisticCancelTokenSource(state, cancelTokenSource: CancelTokenSource | null) {
      state.generalStatisticCancelTokenSource = cancelTokenSource;
    },
    setCampaignStatistic(state, statistic: BaseStatistic) {
      state.campaignStatistic = statistic;
    },
    resetCampaignStatistic(state) {
      state.campaignStatistic = undefined;
    },
    resetDates(state) {
      state.startDate = undefined;
      state.endDate = undefined;
    },
  },
  actions: {
    async setGeneralStatistic(context, data) {
      const { user, customerId, startDate, endDate, zoneIds } = data;
      if (context.state.generalStatisticCancelTokenSource) {
        context.state.generalStatisticCancelTokenSource.cancel();
        context.commit('setGeneralStatisticCancelTokenSource', null);
      }
      context.commit(AlertStoreMutations.SET_IS_LOADING, true, { root: true });
      const CancelToken = axios.CancelToken;
      const source = CancelToken.source();
      context.commit('setGeneralStatisticCancelTokenSource', source);
      const statistic = await getGeneralStatisticUsecase(user, customerId, startDate, endDate, zoneIds, source.token);
      if (context.state.generalStatisticCancelTokenSource?.token === source.token) {
        context.commit('setGeneralStatistic', statistic);
        if (!(statistic?.playoutsPerMonthByZone && statistic?.playoutsPerMonthByZone?.length > 0)) {
          await context.dispatch(
            AlertStoreActions.TRIGGER_ALERT,
            {
              message: 'campaign.campaign-statistics.no-data-available',
              type: AlertType.WARNING,
            },
            { root: true },
          );
        }
        context.commit('setGeneralStatisticCancelTokenSource', null);
        context.commit(AlertStoreMutations.SET_IS_LOADING, false, { root: true });
      } else {
        context.commit(AlertStoreMutations.SET_IS_LOADING, true, { root: true });
      }
    },
    async setCampaignStatistic(context, data) {
      const { user, campaignId, startDate, endDate, zoneIds } = data;
      const statistic = await getCampaignStatisticsUseCase(user, campaignId, startDate, endDate, zoneIds);
      context.commit('setCampaignStatistic', statistic);
    },
  },
};
