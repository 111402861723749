





















import { Component, Mixins } from 'vue-property-decorator';
import { EVENTS } from '@/ui/constant/event.constant';
import SuccessAlert from '@/ui/components/atoms/SuccessAlert.vue';
import { NavigationMixin } from '@/ui/mixins/NavigationMixin';

import { ROUTE_CAMPAIGNS } from '@/ui/router/campaign.routes';
import { ROUTE_NAMES } from '@/ui/constant/routes.constants';
import { CampaignStoreMutations } from '@/ui/stores/CampaignStore';
import { Loader } from '@/ui/util/decorators/loader';
import { CustomerStoreGetters } from '@/ui/stores/CustomerStore';

@Component({
  name: 'AppHeader',
  components: { SuccessAlert },
})
export default class AppHeader extends Mixins(NavigationMixin) {
  get isLoading() {
    return this.$store.state.alertStore.isLoading;
  }

  get companyLogo() {
    return this.selectedCustomer?.logo ? this.selectedCustomer.logo : require('@/ui/assets/logo/jlsLogo.png');
  }

  get selectedCustomer() {
    return this.$store.state.customerStore.customer;
  }

  get isLoginPage() {
    return this.$route.name === ROUTE_NAMES.LOGIN;
  }

  @Loader
  async routeToOverview() {
    if (this.$route.name === ROUTE_NAMES.CAMPAIGNS) {
      this.$store.commit(CampaignStoreMutations.RESET_ALL_FILTERS);
      await this.$store.dispatch(CampaignStoreMutations.SET_CAMPAIGNS);
    } else {
      this.goTo(ROUTE_CAMPAIGNS);
    }
  }

  private setShowDrawer() {
    this.$emit(EVENTS.DRAWER_BUTTON_CLICKED);
  }

  get title() {
    return this.$store.getters[CustomerStoreGetters.GET_PREFERRED_TITLE] || this.$t('header.bar.title');
  }
}
