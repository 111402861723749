import type { LanguageObject } from '@/core/campaign/models/LanguageObject';
import { CreateTagDTO } from '@/core/tags/DTO/CreateTagDTO';
import { TagType } from '@/core/tags/enums/TagType';
import type { Tag } from '@/core/tags/models/Tag';

function getEmptyLanguageObject(): LanguageObject {
  return {
    'de-CH': '',
    'fr-CH': '',
    'it-CH': '',
    'en-CH': '',
  };
}

function getEmptyCampaignTag(type: TagType): CreateTagDTO {
  return { type, value: getEmptyLanguageObject() };
}

function isEmptyOrDuplicate(newTag: CreateTagDTO, allTags: Tag[], locale: keyof LanguageObject) {
  const isEmpty = newTag.value[locale].length == 0;
  const isDuplicate = allTags?.find(
    (item) => item.value[locale].toLocaleLowerCase() == newTag.value[locale].toLocaleLowerCase(),
  );
  return isEmpty || isDuplicate;
}

function getEmptyZoneTag(channel: Tag): CreateTagDTO {
  return {
    type: TagType.ZONE,
    groupTagId: channel.id,
    value: getEmptyLanguageObject(),
    estimatedPlayValue: 0,
  };
}

function whitespaceTrim(translations: LanguageObject): LanguageObject {
  translations['de-CH'] = translations['de-CH']?.trim();
  translations['fr-CH'] = translations['fr-CH']?.trim();
  translations['it-CH'] = translations['it-CH']?.trim();
  translations['en-CH'] = translations['en-CH']?.trim();
  return translations;
}

export { getEmptyLanguageObject, getEmptyCampaignTag, isEmptyOrDuplicate, getEmptyZoneTag, whitespaceTrim };
