import { createDecorator } from 'vue-class-component';
import { AlertStoreMutations } from '@/ui/stores/AlertStore';
import store from '@/ui/store';

export const Loader = createDecorator((options, key) => {
  if (options.methods) {
    const originalMethod = options.methods[key];
    options.methods[key] = async function wrapperMethod(...args) {
      store.commit(AlertStoreMutations.SET_IS_LOADING, true, { root: true });
      await originalMethod.apply(this, args);
      store.commit(AlertStoreMutations.SET_IS_LOADING, false, { root: true });
    };
  }
});
