import Vue, { VueConstructor } from 'vue';
import { formatIsoTimestampToLocalizedTimestamp, formatIsoTimestampToLocalizedShortDate } from './dateTimeFormat';

// eslint-disable-next-line @typescript-eslint/ban-types
const filters: { [key: string]: Function } = {
  isoTimestampToLocalizedTimestamp: (value: string | null) => {
    if (!value) {
      return '';
    }
    return formatIsoTimestampToLocalizedTimestamp(value);
  },
  isoTimestampToLocalizedShortDate: (value: string | null) => {
    if (!value) {
      return '';
    }
    return formatIsoTimestampToLocalizedShortDate(value);
  },
};

export function installFilters(Vue: VueConstructor<Vue>) {
  for (const key in filters) {
    Vue.filter(key, filters[key]);
  }
}
