







import { Component, Vue } from 'vue-property-decorator';
import { AlertStoreGetters, AlertType } from '@/ui/stores/AlertStore';

const ALERT_TO_COLOR: Map<AlertType, string> = new Map([
  [AlertType.ERROR, 'alertError'],
  [AlertType.WARNING, 'alertWarning'],
  [AlertType.SUCCESS, 'alertSuccess'],
  [AlertType.NOT_SET, 'alertNotSet'],
]);

@Component({
  name: 'SuccessAlert',
})
export default class SuccessAlert extends Vue {
  get showFromStore() {
    return this.$store.getters[AlertStoreGetters.IS_ALERT_SHOWN];
  }

  get alertMessage() {
    return this.$store.getters[AlertStoreGetters.MESSAGE];
  }

  get alertType(): AlertType {
    return this.$store.getters[AlertStoreGetters.TYPE];
  }

  get alertIsCustomMessage() {
    return this.$store.getters[AlertStoreGetters.IS_CUSTOM_MESSAGE];
  }

  getColorByType(): string {
    return ALERT_TO_COLOR.get(this.alertType) || 'alertNotSet';
  }
}
