import { container } from 'tsyringe';
import { httpServiceProvider } from '@/infrastructure/http/httpServiceProvider';
import { HttpService } from '@/infrastructure/http/HttpService';
import { HttpMediaService } from '@/infrastructure/media/HttpMediaService';
import { HttpUserService } from '@/infrastructure/user/HttpUserService';
import { HttpCampaignService } from '@/infrastructure/campaign/HttpCampaignService';
import { MsalAuthService } from '@/infrastructure/auth/MsalAuthService';
import { HttpAdminService } from '@/infrastructure/admin/HttpAdminService';
import { HttpStatisticsService } from '@/infrastructure/statistics/HttpStatisticsService';
import { HttpTagsService } from '@/infrastructure/tags/HttpTagsService';
import { HttpCustomerService } from '@/infrastructure/customer/HttpCustomerService';

export const injectDependencies = () => {
  container.register(HttpService, httpServiceProvider());

  container.register('MediaService', {
    useClass: HttpMediaService,
  });
  container.register('UserService', {
    useClass: HttpUserService,
  });
  container.register('CampaignService', {
    useClass: HttpCampaignService,
  });
  container.register('MsalAuthService', {
    useClass: MsalAuthService,
  });
  container.register('AdminService', {
    useClass: HttpAdminService,
  });
  container.register('StatisticsService', {
    useClass: HttpStatisticsService,
  });
  container.register('TagsService', {
    useClass: HttpTagsService,
  });
  container.register('CustomerService', {
    useClass: HttpCustomerService,
  });
};
