import Vue from 'vue';
import Vuex from 'vuex';
import VuexPersistence from 'vuex-persist';
import { UserState, userStore } from '@/ui/stores/UserStore';
import { AdminState, adminStore } from '@/ui/stores/AdminStore';
import { AlertState, alertStore } from '@/ui/stores/AlertStore';
import { CustomerState, customerStore } from '@/ui/stores/CustomerStore';
import { CampaignState, campaignStore } from '@/ui/stores/CampaignStore';
import { StatisticState, statisticStore } from '@/ui/stores/StatisticStore';
import { PermanentState, permanentStore } from '@/ui/stores/PermanentStore';
import { HttpState, httpStore } from '@/ui/stores/HttpStore';

Vue.use(Vuex);

export interface RootState {
  userStore: UserState;
  adminStore: AdminState;
  alertStore: AlertState;
  customerStore: CustomerState;
  campaignStore: CampaignState;
  statisticStore: StatisticState;
  permanentStore: PermanentState;
  httpStore: HttpState;
}

const persistantStore = new VuexPersistence<RootState>({
  storage: window.localStorage,
  reducer: (state: RootState) => ({
    permanentStore: state.permanentStore,
    adminStore: {
      campaignToEdit: state.adminStore.campaignToEdit,
      isAdminCreateCampaign: state.adminStore.isAdminCreateCampaign,
    },
  }),
});

export default new Vuex.Store({
  modules: {
    userStore,
    adminStore,
    alertStore,
    customerStore,
    campaignStore,
    statisticStore,
    permanentStore,
    httpStore,
  },
  plugins: [persistantStore.plugin],
});
