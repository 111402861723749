import { inject, injectable } from 'tsyringe';
import { HttpService } from '@/infrastructure/http/HttpService';
import { CampaignService } from '@/core/campaign/services/CampaignService';
import { Campaign, CampaignData } from '@/core/campaign/models/Campaign';
import { CreateCampaignDTO } from '@/core/campaign/DTO/CreateCampaignDTO';
import { GenericResponse } from '@/infrastructure/models/Response';
import type { CurrentUser } from '@/core/user/models/CurrentUser';
import { API_PATH } from '@/infrastructure/enum/ApiPath';
import { CampaignMedia } from '@/core/campaign/models/CampaignMedia';
import { FindCampaignsDTO } from '@/core/campaign/DTO/FindCampaignsDTO';

@injectable()
export class HttpCampaignService implements CampaignService {
  constructor(
    @inject(HttpService)
    private readonly httpService: HttpService,
  ) {}

  async findCampaigns(currentUser: CurrentUser, campaignsDTO: Partial<FindCampaignsDTO>): Promise<CampaignData> {
    const endpoint = `${API_PATH.CUSTOMER}/${currentUser.customerId}/${currentUser.id}/${API_PATH.FIND_CAMPAIGNS}`;
    const { result } = (await this.httpService.post<GenericResponse<Campaign[]>>(endpoint, campaignsDTO)) || {};
    return { data: result?.data, continuationToken: result?.continuationToken };
  }

  async getCampaignById(currentUser: CurrentUser, campaignId: string): Promise<Campaign> {
    const response = await this.httpService.get<GenericResponse<Campaign>>(
      `${API_PATH.CUSTOMER}/${currentUser.customerId}/${currentUser.id}/${API_PATH.CAMPAIGN}/${campaignId}`,
    );
    return response?.result?.data;
  }

  async createCampaign(data: CreateCampaignDTO, user: CurrentUser): Promise<Campaign> {
    const endpoint = `${API_PATH.CUSTOMER}/${user.customerId}/${user.id}/${API_PATH.CAMPAIGN}`;
    const response = await this.httpService.post<GenericResponse<Campaign>>(endpoint, data);
    return response?.result?.data;
  }

  async updateCampaign(data: Partial<Campaign>, user: CurrentUser, campaignId: string): Promise<void> {
    const endpoint = `${API_PATH.CUSTOMER}/${user.customerId}/${user.id}/${API_PATH.CAMPAIGN}/${campaignId}`;
    await this.httpService.patch<GenericResponse<void>>(endpoint, data);
  }

  async updateCampaignMedium(medium: Partial<CampaignMedia>, user: CurrentUser, campaignId: string): Promise<void> {
    const endpoint = `${API_PATH.CUSTOMER}/${user.customerId}/${user.id}/${API_PATH.CAMPAIGN}/${campaignId}/${API_PATH.MEDIUM}/${medium.id}`;
    await this.httpService.patch<GenericResponse<void>>(endpoint, medium);
  }

  async delete(user: CurrentUser, campaignId: string): Promise<void> {
    const endpoint = `${API_PATH.CUSTOMER}/${user.customerId}/${user.id}/${API_PATH.CAMPAIGN}/${campaignId}`;
    await this.httpService.delete<GenericResponse<void>>(endpoint);
  }
}
