import { inject, injectable } from 'tsyringe';
import { HttpService } from '@/infrastructure/http/HttpService';
import { GenericResponse } from '@/infrastructure/models/Response';
import { AdminService } from '@/core/admin/services/AdminServices';
import type { User } from '@/core/admin/models/User';
import { API_PATH } from '@/infrastructure/enum/ApiPath';
import type { CurrentUser } from '@/core/user/models/CurrentUser';
import { CreateUserDTO } from '@/core/admin/DTO/UserToCreateDTO';
import { UpdateUserDTO } from '@/core/admin/DTO/UpdateUserDto';
import { DeleteUserDTO } from '@/core/admin/DTO/DeleteUserDTO';
import { VersionMetadata } from '@/core/admin/models/VersionMetadata';

@injectable()
export class HttpAdminService implements AdminService {
  constructor(
    @inject(HttpService)
    private readonly httpService: HttpService,
  ) {}

  async getAllUsers(currentUser: CurrentUser): Promise<User[]> {
    const endpoint = `${API_PATH.CUSTOMER}/${currentUser.customerId}/${currentUser.id}/${API_PATH.USERS}`;
    const response = await this.httpService.get<GenericResponse<User[]>>(endpoint);
    return response?.result?.data;
  }

  async getUserById(currentUser: CurrentUser, userId: string): Promise<User> {
    const endpoint = `${API_PATH.CUSTOMER}/${currentUser.customerId}/${currentUser.id}/${API_PATH.USER}/${userId}`;
    const response = await this.httpService.get<GenericResponse<User>>(endpoint);
    return response?.result?.data;
  }

  async createUser(currentUser: CurrentUser, user: CreateUserDTO): Promise<User> {
    const endpoint = `${API_PATH.CUSTOMER}/${currentUser.customerId}/${currentUser.id}/${API_PATH.USER}`;
    const response = await this.httpService.post<GenericResponse<User>>(endpoint, user);
    return response?.result?.data;
  }

  async updateUser(currentUser: CurrentUser, user: UpdateUserDTO): Promise<void> {
    const endpoint = `${API_PATH.CUSTOMER}/${currentUser.customerId}/${currentUser.id}/${API_PATH.USER}/${user.id}`;
    await this.httpService.patch<GenericResponse<void>>(endpoint, user);
  }

  async deleteUser(currentUser: CurrentUser, user: DeleteUserDTO): Promise<void> {
    const endpoint = `${API_PATH.CUSTOMER}/${currentUser.customerId}/${currentUser.id}/${API_PATH.USER}/${user.userId}`;
    await this.httpService.delete<GenericResponse<void>>(endpoint);
  }

  async getVersion(currentUser: CurrentUser): Promise<VersionMetadata> {
    const endpoint = `${API_PATH.VERSION}/${currentUser.id}`;
    const response = await this.httpService.get<GenericResponse<VersionMetadata>>(endpoint);
    return response?.result?.data;
  }
}
