import { render, staticRenderFns } from "./OverflowChip.vue?vue&type=template&id=43819e30&scoped=true&"
import script from "./OverflowChip.vue?vue&type=script&lang=ts&"
export * from "./OverflowChip.vue?vue&type=script&lang=ts&"
import style0 from "./OverflowChip.vue?vue&type=style&index=0&id=43819e30&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "43819e30",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VChip } from 'vuetify/lib/components/VChip';
installComponents(component, {VChip})
