import { ApplicationInsights } from '@microsoft/applicationinsights-web';

let appInsights: ApplicationInsights;
const disableAppInsights = process.env.VUE_APP_APPLICATION_INSIGHTS_DISABLED;

function initializeAppInsights() {
  if (!process.env.VUE_APP_INSIGHTS_KEY) {
    throw new Error(
      'Application Insights instrumentation Key is missing. Please set VUE_APP_INSIGHTS_KEY environment variable',
    );
  }
  if (!appInsights) {
    appInsights = new ApplicationInsights({
      config: {
        instrumentationKey: process.env.VUE_APP_INSIGHTS_KEY,
        disableTelemetry: disableAppInsights,
      },
    });
    appInsights.loadAppInsights();
    // Manually call trackPageView to establish the current user/session/page view
    appInsights.trackPageView();
  }
}

function getAppInsights(): ApplicationInsights {
  if (!appInsights) {
    initializeAppInsights();
    return appInsights;
  } else {
    return appInsights;
  }
}

export { getAppInsights };
