import { Module } from 'vuex';
import { RootState } from '@/ui/store';
import { Canceler } from 'axios';

export const HttpStoreMutations = {
  ADD_REQUEST: 'httpStore/addRequest',
};
export const HttpStoreActions = {
  CANCEL_REQUESTS: 'httpStore/cancelRequests',
};

interface ActiveRequest {
  cancel: Canceler;
}

export interface HttpState {
  activeRequests: ActiveRequest[];
}

export const httpStore: Module<HttpState, RootState> = {
  namespaced: true,
  state: {
    activeRequests: [],
  },
  mutations: {
    addRequest(state, request: ActiveRequest) {
      state.activeRequests.push(request);
    },
  },
  actions: {
    cancelRequests(context) {
      if (context.state.activeRequests?.length > 0) {
        context.state?.activeRequests.forEach((req) => {
          if (req && req.cancel) {
            req.cancel();
          }
        });
        if (context.rootState.statisticStore.generalStatisticCancelTokenSource) {
          context.rootState.statisticStore.generalStatisticCancelTokenSource.cancel();
        }
        context.state.activeRequests = [];
      }
    },
  },
};
