import { inject, injectable } from 'tsyringe';
import { HttpService } from '@/infrastructure/http/HttpService';
import { TagsService } from '@/core/tags/services/TagsService';
import { GenericResponse } from '@/infrastructure/models/Response';
import type { CurrentUser } from '@/core/user/models/CurrentUser';
import { API_PATH } from '@/infrastructure/enum/ApiPath';
import { FindTagsDTO } from '@/core/tags/DTO/FindTagsDTO';
import { UpdateTagDTO } from '@/core/tags/DTO/UpdateTagDTO';
import { CreateTagDTO } from '@/core/tags/DTO/CreateTagDTO';
import type { Tag } from '@/core/tags/models/Tag';

@injectable()
export class HttpTagsService implements TagsService {
  constructor(
    @inject(HttpService)
    private readonly httpService: HttpService,
  ) {}

  async getTagById(user: CurrentUser, tagId: string): Promise<Tag> {
    const response = await this.httpService.get<GenericResponse<Tag>>(
      `${this.getBasePath(user.customerId, user.id)}/${tagId}`,
    );
    return response?.result?.data;
  }

  async findTags(tagsDTO: FindTagsDTO, user: CurrentUser): Promise<Tag[]> {
    const response = await this.httpService.post<GenericResponse<Tag[]>>(
      `${this.getBasePath(user.customerId, user.id)}/find`,
      tagsDTO,
    );
    return response?.result?.data;
  }

  async createTag(data: CreateTagDTO, user: CurrentUser): Promise<Tag> {
    const response = await this.httpService.post<GenericResponse<Tag>>(
      `${this.getBasePath(user.customerId, user.id)}`,
      data,
    );
    return response?.result?.data;
  }

  async updateTag(tagId: string, data: UpdateTagDTO, user: CurrentUser): Promise<void> {
    await this.httpService.patch<GenericResponse<void>>(`${this.getBasePath(user.customerId, user.id)}/${tagId}`, data);
  }

  async deleteTag(tagId: string, user: CurrentUser): Promise<void> {
    await this.httpService.delete<GenericResponse<void>>(`${this.getBasePath(user.customerId, user.id)}/${tagId}`);
  }

  private getBasePath(customerId: string, userId: string): string {
    return `${API_PATH.CUSTOMER}/${customerId}/${userId}/tag`;
  }
}
