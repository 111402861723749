export enum API_PATH {
  CUSTOMER = 'customer',
  CAMPAIGN = 'campaign',
  CAMPAIGNS = 'campaigns',
  MEASUREMENTS = 'measurements',
  FIND_CAMPAIGNS = 'campaign/find',
  MEDIUM = 'medium',
  USERS = 'users',
  USER = 'user',
  VERSION = 'version',
}
