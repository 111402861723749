


































import { mapState } from 'vuex';
import { Component, Mixins } from 'vue-property-decorator';
import { NavigationMixin } from '@/ui/mixins/NavigationMixin';
import { NavigationLink } from '@/ui/models/NavigationLink';
import { logoutUserUseCase } from '@/core/auth/usecases/logoutUserUseCase';
import { UserState } from '@/ui/stores/UserStore';
import { USER_ROLE } from '@/core/user/enums/UserRole';
import { USER_PERMISSION } from '@/core/user/enums/UserPermission';
import { ROUTE_CUSTOMERS, ROUTE_STATISTICS, ROUTE_TAG_ARCHIVE } from '@/ui/router/routes';
import { ROUTE_ADMIN } from '@/ui/router/admin.routes';
import { ROUTE_CAMPAIGNS, ROUTE_CREATE_CAMPAIGN } from '@/ui/router/campaign.routes';
import { PermanentStoreMutations } from '@/ui/stores/PermanentStore';
import { AdminStoreMutations } from '@/ui/stores/AdminStore';

@Component({
  name: 'NavigationMenu',
  computed: {
    ...mapState<UserState>('userStore', {
      role: (state: UserState) => state.currentUser?.role,
      permissions: (state: UserState) => state.currentUser?.permissions,
    }),
  },
})
export default class NavigationMenu extends Mixins(NavigationMixin) {
  private readonly role!: USER_ROLE;
  private readonly permissions!: USER_PERMISSION[];

  private readonly navigationLinks: NavigationLink[] = [
    {
      route: ROUTE_CAMPAIGNS,
      icon: 'grid',
      text: 'header.menu.campaigns',
    },
    {
      route: ROUTE_CREATE_CAMPAIGN,
      icon: 'plus',
      text: 'header.menu.createCampaign',
      requiredRoles: [USER_ROLE.EDITOR, USER_ROLE.ADMIN, USER_ROLE.SUPER_ADMIN],
    },
    {
      route: ROUTE_STATISTICS,
      icon: 'stats',
      text: 'header.menu.statistics',
      requiredPermissions: [USER_PERMISSION.STATISTICS],
      requiredRoles: [USER_ROLE.USER, USER_ROLE.EDITOR, USER_ROLE.ADMIN, USER_ROLE.SUPER_ADMIN],
    },
    {
      route: ROUTE_TAG_ARCHIVE,
      icon: 'dots-grid',
      text: 'header.menu.labelArchive',
      requiredRoles: [USER_ROLE.EDITOR, USER_ROLE.ADMIN, USER_ROLE.SUPER_ADMIN],
    },
    {
      route: ROUTE_ADMIN,
      icon: 'admin',
      text: 'header.menu.admin',
      requiredRoles: [USER_ROLE.ADMIN, USER_ROLE.SUPER_ADMIN],
    },
    {
      route: ROUTE_CUSTOMERS,
      icon: 'people',
      text: 'header.menu.customers',
      requiredRoles: [USER_ROLE.SUPER_ADMIN],
    },
  ];

  get isSuperAdmin() {
    return this.role === USER_ROLE.SUPER_ADMIN;
  }

  get allowedNavigationLinks(): NavigationLink[] {
    if (!this.role) {
      return [];
    }

    return this.navigationLinks.filter((link) => {
      if (this.isSuperAdmin) {
        return true;
      }

      const requiredRoles = link.requiredRoles;
      const requiredPermissions = link.requiredPermissions;
      if (!requiredRoles && !requiredPermissions) {
        return true;
      }

      const requiresRoleAndPermission = requiredRoles && requiredPermissions;
      if (requiresRoleAndPermission) {
        const hasAllRequiredPermissions = requiredPermissions?.every((p) => this.permissions.includes(p));
        const hasRequiredRole = requiredRoles?.includes(this.role);
        return hasAllRequiredPermissions && hasRequiredRole;
      }

      if (requiredRoles && requiredRoles.includes(this.role)) {
        return true;
      }

      if (requiredPermissions && requiredPermissions?.every((p) => this.permissions.includes(p))) {
        return true;
      }

      return false;
    });
  }

  async doLogout() {
    this.$store.commit(PermanentStoreMutations.RESET_PERMANENT_STORE);
    this.$store.commit(AdminStoreMutations.RESET_ALL_FILTERS);
    await logoutUserUseCase();
  }
}
