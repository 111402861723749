import { container } from 'tsyringe';
import { CampaignService } from '@/core/campaign/services/CampaignService';
import { CampaignData } from '@/core/campaign/models/Campaign';
import type { CurrentUser } from '@/core/user/models/CurrentUser';
import { FindCampaignsDTO } from '@/core/campaign/DTO/FindCampaignsDTO';
import { DEFAULT_SIZE } from '@/ui/constant/size.constant';

function prepareDTO(dto: FindCampaignsDTO) {
  if (!dto.size) {
    dto.size = DEFAULT_SIZE.CAMPAIGNS;
  }
  if (!dto.search) {
    dto.search = '';
  }
  return dto;
}

export const findCampaignsUseCase = async (
  currentUser: CurrentUser,
  campaignsDTO: FindCampaignsDTO,
): Promise<CampaignData> => {
  const campaignService = container.resolve<CampaignService>('CampaignService');
  const dto = prepareDTO(campaignsDTO);
  const campaign = await campaignService.findCampaigns(currentUser, dto);
  return campaign;
};
