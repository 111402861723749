






















import Vue from 'vue';
import { Component, Watch } from 'vue-property-decorator';
import AppHeader from '@/ui/components/organisms/AppHeader.vue';
import NavigationMenu from '@/ui/components/organisms/NavigationMenu.vue';
import { CustomerStoreGetters } from '@/ui/stores/CustomerStore';
import { AppThemeType } from '@/core/shared/model/Setting';
import store from './store';

const DEFAULT_BLUE = '#00b8fe';
const DEFAULT_UBS = '#E60100';

@Component({
  name: 'App',
  components: { AppHeader, NavigationMenu },
  computed: {
    storePreferredTheme: (): string | undefined => store.getters[CustomerStoreGetters.GET_PREFERRED_THEME],
  },
})
export default class App extends Vue {
  private showDrawer = false;

  private setShowDrawer() {
    this.showDrawer = !this.showDrawer;
  }

  @Watch('storePreferredTheme')
  onStorePreferredThemeChange(newValue: string | undefined) {
    // since the only difference should be the accent color this gave the idea
    // https://github.com/eyuelberga/vuetify-dynamic-theme/blob/master/src/components/ThemeChanger.vue
    this.$vuetify.theme.themes.light.accent = newValue === AppThemeType.DEFAULT_UBS ? DEFAULT_UBS : DEFAULT_BLUE;
  }
}
