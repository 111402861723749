









































































/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { Component, Mixins, Watch } from 'vue-property-decorator';
import AppHeader from '@/ui/components/organisms/AppHeader.vue';
import CampaignsFilter from '@/ui/components/organisms/filter/CampaignsFilter.vue';
import CampaignsList from '@/ui/components/organisms/campaigns/CampaignsList.vue';
import { NavigationMixin } from '@/ui/mixins/NavigationMixin';
import type { Campaign } from '@/core/campaign/models/Campaign';
import { CampaignMedia } from '@/core/campaign/models/CampaignMedia';
import { AlertStoreActions, AlertType } from '@/ui/stores/AlertStore';
import { CampaignStoreMutations } from '@/ui/stores/CampaignStore';
import { Loader } from '@/ui/util/decorators/loader';
import { ROUTE_CREATE_CAMPAIGN } from '../router/campaign.routes';
import { USER_ROLE } from '@/core/user/enums/UserRole';
import LabelChip from '@/ui/components/atoms/groupingItems/LabelChip.vue';
import { PermanentStoreMutations } from '@/ui/stores/PermanentStore';
import { findTagsUseCase } from '@/core/tags/usecases/findTagsUseCase';
import { TagType } from '@/core/tags/enums/TagType';
import type { CurrentUser } from '@/core/user/models/CurrentUser';
import ButtonWithTextAndIcon from '@/ui/components/atoms/buttons/ButtonWithTextAndIcon.vue';

@Component({
  name: 'CampaignsView',
  components: { CampaignsFilter, AppHeader, CampaignsList, ButtonWithTextAndIcon, LabelChip },
})
export default class CampaignsView extends Mixins(NavigationMixin) {
  protected searchTerm = '';
  private activeSearchTerm = '';
  private showSearchTag = false;

  created() {
    if (!this.allAvailableCampaignZones) {
      this.fetchTags('');
    }
    return this.campaigns?.length > 0
      ? this.campaigns
      : this.fetchNewCampaignData().then(() => this.areCampaignsAvailable());
  }

  mounted() {
    this.activeSearchTerm = this.activeSearchTermFilter;
    if (this.activeSearchTerm != undefined && this.activeSearchTerm != '') {
      this.showSearchTag = true;
    }
  }

  @Watch('activeSearchTermFilter')
  onReset(val: string) {
    if (!val) {
      this.resetSearchDisplay();
    }
  }

  get allAvailableCampaignZones() {
    return this.$store.state.campaignStore.allAvailableCampaignZones;
  }

  get zones() {
    return this.allAvailableCampaignZones || [];
  }

  get selectedSubjectFilter() {
    return this.$store.state.campaignStore.selectedSubjectTags || [];
  }

  get selectedLabelFilter() {
    return this.$store.state.campaignStore.selectedGeneralTags || [];
  }

  get selectedZones() {
    return this.$store.state.campaignStore.selectedCampaignsZones;
  }

  get selectedDates() {
    return this.$store.state.campaignStore.selectedDates;
  }

  get currentUser(): CurrentUser | undefined {
    return this.$store.state.userStore.currentUser;
  }

  get campaigns(): Campaign[] {
    return this.$store.state.campaignStore.campaigns;
  }

  get activeSearchTermFilter() {
    return this.$store.state.campaignStore.selectedSearchTerm;
  }

  get continuationToken(): number {
    return this.$store.state.campaignStore.continuationToken;
  }

  get allCampaignMedia(): CampaignMedia[] {
    if (!this.campaigns) {
      return [];
    }
    return this.campaigns.flatMap((campaign) => campaign.media);
  }

  get canCreateNewCampaign() {
    return this.$store.state.userStore.currentUser?.role !== USER_ROLE.USER;
  }

  @Loader
  async fetchTags(search: string) {
    if (this.currentUser) {
      const zones = await findTagsUseCase({ search, type: TagType.ZONE }, this.currentUser);
      this.$store.commit(CampaignStoreMutations.SET_ALL_AVAILABLE_CAMPAIGN_ZONES, zones);
    }
  }

  @Loader
  async fetchNewCampaignData() {
    await this.$store.dispatch(CampaignStoreMutations.SET_CAMPAIGNS);
  }

  @Loader
  async loadMore() {
    await this.$store.dispatch(CampaignStoreMutations.LOAD_MORE_CAMPAIGNS);
  }

  @Loader
  async updateSearchTerm() {
    this.$store.commit(CampaignStoreMutations.SET_FILTER_SEARCH, this.searchTerm);
    await this.$store.dispatch(CampaignStoreMutations.SET_CAMPAIGNS);
    this.areCampaignsAvailable();
    this.activeSearchTerm = this.searchTerm;
    this.searchTerm = '';
    this.showSearchTag = this.activeSearchTerm?.length > 0;
  }

  @Loader
  async updateSearchTermAfterRemovingLabel() {
    this.$store.commit(CampaignStoreMutations.SET_FILTER_SEARCH, '');
    await this.$store.dispatch(CampaignStoreMutations.SET_CAMPAIGNS);
    this.areCampaignsAvailable();
  }

  areCampaignsAvailable() {
    if (this.campaigns?.length === 0) {
      this.$store.dispatch(AlertStoreActions.TRIGGER_ALERT, {
        message: 'campaign.campaigns.no-campaigns',
        type: AlertType.WARNING,
      });
    }
  }

  resetSearchDisplay() {
    this.activeSearchTerm = '';
    this.searchTerm = '';
    this.showSearchTag = false;
  }

  removeLabel() {
    this.activeSearchTerm = '';
    this.showSearchTag = false;
    this.updateSearchTermAfterRemovingLabel();
  }

  goToCreateCampaign() {
    this.$store.commit(PermanentStoreMutations.SET_LAST_SAVED_PARENT_ROUTE_FOR_EDIT_CAMPAIGN, this.$route.name);
    this.goTo(ROUTE_CREATE_CAMPAIGN);
  }

  updateLastSavedParentRoute(routeName: string) {
    this.$store.commit(PermanentStoreMutations.SET_LAST_SAVED_PARENT_ROUTE_FOR_CAMPAIGN_DETAILS, routeName);
    this.$store.commit(PermanentStoreMutations.SET_LAST_SAVED_PARENT_ROUTE_PARAMS_FOR_EDIT_CAMPAIGN, undefined);
  }
}
