













import { Component, Prop, Vue } from 'vue-property-decorator';
import type { Tag } from '@/core/tags/models/Tag';

@Component({})
export default class OverflowChip extends Vue {
  @Prop({ default: undefined }) tags!: Tag[];
  @Prop({ default: 3 }) numberOfFilterTags!: number;

  getNumberOfOverflowTags() {
    const numberOfSelectedTags = this.tags.length;
    const overflowTags = numberOfSelectedTags - this.numberOfFilterTags;
    return '+' + overflowTags;
  }

  shouldDisplayOverflowNumber() {
    return this.tags.length - this.numberOfFilterTags > 0;
  }
}
