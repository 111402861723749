import Vue from 'vue';
import VueRouter, { Route } from 'vue-router';
import { ROUTES as routes, ROUTE_BASE, ROUTE_LOGIN } from '@/ui/router/routes';
import store from '@/ui/store';
import { HttpStoreActions } from '@/ui/stores/HttpStore';
import { USER_PERMISSION } from '@/core/user/enums/UserPermission';
import { ROUTE_CAMPAIGNS } from '@/ui/router/campaign.routes';

Vue.use(VueRouter);

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior() {
    return { x: 0, y: 0 };
  },
});

function checkAuthorization(to: Route, from: Route, next: () => void) {
  const user = store.state.userStore.currentUser;
  if (!user) {
    return router.push({ name: ROUTE_LOGIN.name, query: { redirect: to.fullPath } });
  }
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const { authorizedUserRoles, requiredPermissions } = to.meta as any;

  const userHasRequiredPermissions =
    requiredPermissions?.length > 0
      ? requiredPermissions.every((p: USER_PERMISSION) => user.permissions.includes(p))
      : true;

  const isUserAuthorized =
    user && authorizedUserRoles?.length && authorizedUserRoles.includes(user.role) && userHasRequiredPermissions;

  return isUserAuthorized ? next() : router.push({ name: ROUTE_CAMPAIGNS.name });
}

router.beforeEach((to, from, next) => {
  if (to.path !== ROUTE_LOGIN.path && to.path !== ROUTE_BASE.path) {
    store.dispatch(HttpStoreActions.CANCEL_REQUESTS, store.state.statisticStore);
    checkAuthorization(to, from, next);
  } else {
    next();
  }
});

export default router;
