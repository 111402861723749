import { Module } from 'vuex';
import { RootState } from '@/ui/store';

export const PermanentStoreMutations = {
  SET_LAST_SAVED_PARENT_ROUTE_FOR_CAMPAIGN_DETAILS: 'permanentStore/setLastSavedParentRouteForCampaignDetails',
  SET_LAST_SAVED_PARENT_ROUTE_FOR_EDIT_CAMPAIGN: 'permanentStore/setLastSavedParentRouteForEditCampaign',
  SET_LAST_SAVED_PARENT_ROUTE_PARAMS_FOR_EDIT_CAMPAIGN: 'permanentStore/setLastSavedParentRouteParamsForEditCampaign',
  RESET_PERMANENT_STORE: 'permanentStore/resetPermanentStore',
};

export interface PermanentState {
  lastSavedParentRouteForCampaignDetails: string | undefined;
  lastSavedParentRouteForEditCampaign: string | undefined;
  lastSavedParentRouteParamsForEditCampaign: string | undefined;
}

export const permanentStore: Module<PermanentState, RootState> = {
  namespaced: true,
  state: {
    lastSavedParentRouteForCampaignDetails: undefined,
    lastSavedParentRouteForEditCampaign: undefined,
    lastSavedParentRouteParamsForEditCampaign: undefined,
  },
  mutations: {
    setLastSavedParentRouteForCampaignDetails(state, routeName: string) {
      state.lastSavedParentRouteForCampaignDetails = routeName;
    },
    setLastSavedParentRouteForEditCampaign(state, routeName: string) {
      state.lastSavedParentRouteForEditCampaign = routeName;
    },
    setLastSavedParentRouteParamsForEditCampaign(state, routeParams: string) {
      state.lastSavedParentRouteParamsForEditCampaign = routeParams;
    },
    resetPermanentStore(state) {
      state.lastSavedParentRouteForCampaignDetails = undefined;
      state.lastSavedParentRouteForEditCampaign = undefined;
      state.lastSavedParentRouteParamsForEditCampaign = undefined;
    },
  },
};
