































import { Component, Prop, Vue } from 'vue-property-decorator';
import LabelFilter from '@/ui/components/molecules/filter/LabelFilter.vue';
import DateFilter from '@/ui/components/molecules/filter/DateFilter.vue';
import ChannelFilter from '@/ui/components/molecules/filter/ChannelFilter.vue';
import SubjectFilter from '@/ui/components/molecules/filter/SubjectFilter.vue';
import getLogger from '@/shared/logger/logger';
import { CampaignMedia } from '@/core/campaign/models/CampaignMedia';
import type { Tag } from '@/core/tags/models/Tag';
import { CampaignStoreGetters, CampaignStoreMutations } from '@/ui/stores/CampaignStore';
import { Loader } from '@/ui/util/decorators/loader';
import { TagType } from '@/core/tags/enums/TagType';
import { findTagsUseCase } from '@/core/tags/usecases/findTagsUseCase';
import i18n from '@/i18n';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const LOG = getLogger('CampaignsFilter');

@Component({
  name: 'CampaignsFilter',
  components: { DateFilter, ChannelFilter, LabelFilter, SubjectFilter },
})
export default class CampaignsFilter extends Vue {
  @Prop({ default: undefined }) campaignMedia: CampaignMedia[] | undefined;
  @Prop() zones: Tag[] | undefined;
  @Prop() selectedZones!: Tag[];
  @Prop() selectedDates: string[] | undefined;
  @Prop() selectedLabelFilter!: Tag[];
  @Prop() selectedSubjectFilter!: Tag[];
  private allGeneralTags: Array<Tag> = [];
  private allSubjectTags: Array<Tag> = [];

  get buttonText() {
    return this.$store.getters[CampaignStoreGetters.HAS_SELECTED_FILTERS]
      ? i18n.i18next.t('filter.campaigns.reset')
      : i18n.i18next.t('filter.campaigns.reload');
  }

  @Loader
  async fetchSubjects(search: string) {
    if (search.length === 0) this.allSubjectTags = [];
    else {
      this.allSubjectTags = await findTagsUseCase(
        { type: TagType.SUBJECT, search: search },
        this.$store.state.userStore.currentUser,
      );
    }
  }

  @Loader
  async updateGeneralTags(tags: Tag[]) {
    this.$store.commit(CampaignStoreMutations.SET_FILTER_GENERAL_TAGS, tags);
    await this.$store.dispatch(CampaignStoreMutations.SET_CAMPAIGNS);
    this.$emit('updated');
  }

  @Loader
  async updateZones(zones: Tag[]) {
    await this.$store.dispatch(CampaignStoreMutations.SET_FILTER_CAMPAIGNS_ZONES, zones);
    this.$emit('updated');
  }

  @Loader
  async updateDates(dates: string[]) {
    this.$store.commit(CampaignStoreMutations.SET_FILTER_DATES, dates);
    await this.$store.dispatch(CampaignStoreMutations.SET_CAMPAIGNS);
    this.$emit('updated');
  }

  @Loader
  async updateSubjects(tags: Tag[]) {
    this.$store.commit(CampaignStoreMutations.SET_FILTER_SUBJECT_TAGS, tags);
    await this.$store.dispatch(CampaignStoreMutations.SET_CAMPAIGNS);
    this.$emit('updated');
  }

  @Loader
  async resetAllFilters() {
    this.$store.commit(CampaignStoreMutations.RESET_ALL_FILTERS);
    await this.$store.dispatch(CampaignStoreMutations.SET_CAMPAIGNS);
    this.$emit('resetSearch');
  }

  async fetchGeneralTags(search: string) {
    if (search.length === 0) this.allGeneralTags = [];
    else {
      this.allGeneralTags = await findTagsUseCase(
        { type: TagType.GENERAL, search: search },
        this.$store.state.userStore.currentUser,
      );
    }
  }
}
