


























import { Component, Mixins, Prop } from 'vue-property-decorator';
import getLogger from '@/shared/logger/logger';
import CampaignCard from '@/ui/components/molecules/campaigns/CampaignCard.vue';
import type { Campaign } from '@/core/campaign/models/Campaign';
import { groupBy as _groupBy } from 'lodash';
import { NavigationMixin } from '@/ui/mixins/NavigationMixin';
import i18n from '@/i18n';
import { ROUTE_CAMPAIGN_DETAIL } from '@/ui/router/campaign.routes';
import { RouteConfig } from 'vue-router';

/* eslint-disable-next-line @typescript-eslint/no-unused-vars */
const LOG = getLogger('CampaignsList');

type SortedCampaignsByMonthAndYear = [string, Campaign[]];

@Component({
  name: 'CampaignsList',
  components: { CampaignCard },
})
export default class CampaignsList extends Mixins(NavigationMixin) {
  @Prop({ default: undefined }) campaigns: Campaign[] | undefined;
  protected CampaignDetail = ROUTE_CAMPAIGN_DETAIL;
  private locale = i18n.i18next.language;

  get hasCampaigns(): boolean {
    return this.campaigns?.length !== 0;
  }

  get allPanelIndices(): number[] {
    const numPanels = this.sortedMonths.length;
    return [...Array(numPanels).keys()];
  }

  get sortedMonths(): SortedCampaignsByMonthAndYear[] {
    const sortCampaignsByNewest: Campaign[] | undefined = this.campaigns?.sort((a: Campaign, b: Campaign) => {
      return a.startDate > b.startDate ? -1 : a.startDate < b.startDate ? 1 : 0;
    });
    const groupByUpcomingCampaigns = _groupBy(sortCampaignsByNewest, this.isUpcoming);
    const hasUpcomingCampaigns: boolean = groupByUpcomingCampaigns.true?.length > 0;
    const pastCampaignsByMonth: SortedCampaignsByMonthAndYear[] = Object.entries(
      _groupBy(groupByUpcomingCampaigns.false, this.getMonth),
    );
    return hasUpcomingCampaigns
      ? [this.getUpcomingCampaigns(groupByUpcomingCampaigns.true), ...pastCampaignsByMonth]
      : pastCampaignsByMonth;
  }

  getMonth(item: Campaign): string {
    return new Date(item.startDate).toLocaleString(this.locale, { month: 'long', year: 'numeric' });
  }

  isUpcoming(item: Campaign): boolean {
    return new Date(item.startDate) > new Date();
  }

  getUpcomingCampaigns(array: Campaign[]): SortedCampaignsByMonthAndYear {
    return [this.$t('campaign.campaigns.upcoming'), array];
  }

  saveRouteAndGoTo(route: RouteConfig, cardId: string) {
    this.$emit('updateLastSavedParentRoute', this.$route.name);
    this.goTo(route, { campaignId: cardId });
  }
}
