export enum StorageFileQuality {
  ORIGINAL = 'original',
  CONVERTED = 'converted',
  LOW = 'low',
  MEDIUM = 'medium',
  HIGH = 'high',
}

export enum MediumStatus {
  IN_UPLOAD = 'IN_UPLOAD',
  UPLOADED = 'UPLOADED',
  DELETED = 'DELETED',
}

export enum CampaignStatus {
  CREATION_IN_PROGRESS = 'creation_in_progress',
  CREATION_DONE = 'creation_done',
  DELETED = 'deleted',
}

export enum CampaignMediaContentType {
  VIDEO = 'VIDEO',
  IMAGE = 'IMAGE',
}

export enum Orientation {
  PORTRAIT = 'Portrait',
  LANDSCAPE = 'Landscape',
}
