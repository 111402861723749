import type { Customer } from '@/core/customer/models/Customer';
import { inject, injectable } from 'tsyringe';
import { HttpService } from '@/infrastructure/http/HttpService';
import { CustomerService } from '@/core/customer/services/CustomerService';
import { GenericResponse } from '@/infrastructure/models/Response';
import { CreateCustomerLogoResponseDTO, CreateCustomerDTO } from '@/core/customer/DTO/CreateCustomerDTO';
import type { CurrentUser } from '@/core/user/models/CurrentUser';
import { API_PATH } from '../enum/ApiPath';

import axios from 'axios';
import { UpdateCustomerDTO } from '@/core/customer/DTO/UpdateCustomerDTO';
import { CustomerPreferences, Setting } from '@/core/shared/model/Setting';

@injectable()
export class HttpCustomerService implements CustomerService {
  constructor(
    @inject(HttpService)
    private readonly httpService: HttpService,
  ) {}

  async getCustomer(userId: string): Promise<Customer> {
    const response = await this.httpService.post<GenericResponse<Customer>>(`${API_PATH.CUSTOMER}/${userId}/find`);
    return response?.result?.data;
  }

  async getAllCustomers(userId: string): Promise<Customer[]> {
    const response = await this.httpService.post<GenericResponse<Customer[]>>(`${API_PATH.CUSTOMER}/${userId}/findAll`);
    return response?.result?.data;
  }

  async createCustomer(userId: string, customer: CreateCustomerDTO): Promise<Customer> {
    const response = await this.httpService.post<GenericResponse<Customer>>(`${API_PATH.CUSTOMER}/${userId}`, customer);
    return response?.result?.data;
  }

  async updateCustomer(userId: string, customer: UpdateCustomerDTO): Promise<void> {
    await this.httpService.patch<GenericResponse<void>>(`${API_PATH.CUSTOMER}/${customer.id}/${userId}`, customer);
  }

  async createLogoFileUploadLink(
    file: File,
    user: CurrentUser,
    customerId: string,
  ): Promise<CreateCustomerLogoResponseDTO> {
    const endpoint = `${API_PATH.CUSTOMER}/${customerId}/${user.id}/logo`;
    const response = await this.httpService.post<GenericResponse<CreateCustomerLogoResponseDTO>>(endpoint, {
      originalFileName: file.name,
      contentType: file.type,
    });
    return response?.result?.data;
  }

  async uploadLogo(endpoint: string, file: File): Promise<void> {
    await axios.put(endpoint, file, {
      headers: {
        'Content-Type': file.type,
        'x-ms-blob-type': 'BlockBlob',
      },
    });
  }

  async getCustomerPreferences(customerId: string, userId: string): Promise<Setting<CustomerPreferences>> {
    const response = await this.httpService.get<GenericResponse<Setting<CustomerPreferences>>>(
      `customer/${customerId}/${userId}/preferences`,
    );
    return response?.result?.data;
  }

  async upsertCustomerPreferences(
    customerId: string,
    userId: string,
    customerPreferences: CustomerPreferences,
  ): Promise<void> {
    await this.httpService.patch<GenericResponse<void>>(
      `customer/${customerId}/${userId}/preferences`,
      customerPreferences,
    );
  }
}
