import { getAppInsights } from '@/infrastructure/error/InsightsService';
import getLogger from '@/shared/logger/logger';

const appInsights = getAppInsights();

const log = getLogger('ErrorHandler');

function errorHandler(err: Error) {
  log.warn(err);
  appInsights.trackException({ exception: err });
}

export { errorHandler };
