import type { Campaign } from '@/core/campaign/models/Campaign';
import { Module } from 'vuex';
import { RootState } from '@/ui/store';
import { findCampaignsUseCase } from '@/core/campaign/usecases/findCampaignsUseCase';
import type { Tag } from '@/core/tags/models/Tag';
import { map as _map } from 'lodash';

export const CampaignStoreMutations = {
  SET_CAMPAIGNS: 'campaignStore/setCampaigns',
  LOAD_MORE_CAMPAIGNS: 'campaignStore/loadMoreCampaigns',

  SET_ALL_AVAILABLE_CAMPAIGN_ZONES: 'campaignStore/setAllAvailableCampaignZones',
  SET_FILTER_GENERAL_TAGS: 'campaignStore/setFilterGeneralTags',
  SET_FILTER_CAMPAIGNS_ZONES: 'campaignStore/setCampaignsZones',
  SET_FILTER_STATISTICS_ZONES: 'campaignStore/setFilterStatisticsZones',

  SET_FILTER_DETAIL_ZONES: 'campaignStore/setFilterDetailZones',
  SET_FILTER_DETAIL_SUBJECTS: 'campaignStore/setDetailSubjects',
  SET_FILTER_DETAIL_LANGUAGES: 'campaignStore/setDetailLanguages',
  SET_FILTER_DETAIL_FORMATS: 'campaignStore/setDetailFormats',
  SET_FILTER_STATISTICS_DETAIL_ZONES: 'campaignStore/setStatisticsDetailZones',
  SET_FILTER_STATISTICS_DETAIL_DATES: 'campaignStore/setStatisticsDetailDates',

  RESET_CAMPAIGN_DETAIL: 'campaignStore/resetCampaignDetails',
  RESET_CAMPAIGN_STATISTICS_DETAIL: 'campaignStore/resetCampaignStatisticsDetails',

  SET_FILTER_SUBJECT_TAGS: 'campaignStore/setFilterSubjectTags',
  SET_FILTER_DATES: 'campaignStore/setFilterDates',
  SET_FILTER_SEARCH: 'campaignStore/setFilterSearch',
  RESET_ALL_FILTERS: 'campaignStore/resetAllFilters',
};

export const CampaignStoreActions = {
  TRIGGER_SET_SELECTED_CAMPAIGN: 'campaignStore/triggerSetSelectedCampaign',
  TRIGGER_DELETE_CAMPAIGN: 'campaignStore/triggerDeleteCampaign',
};

export const CampaignStoreGetters = {
  GET_SELECTED_CAMPAIGN: 'campaignStore/getSelectedCampaign',
  HAS_SELECTED_FILTERS: 'campaignStore/hasSelectedFilters',
};

export interface CampaignState {
  campaigns: Campaign[] | undefined;
  continuationToken: number | undefined;
  selectedCampaign: Campaign | undefined;

  // tags
  allAvailableCampaignZones: Tag[] | undefined;
  allGeneralTags: Tag[] | undefined;
  allCampaignsZones: Tag[] | undefined;
  selectedGeneralTags: Tag[] | undefined;
  selectedSubjectTags: Tag[] | undefined;
  selectedCampaignsZones: string[] | undefined;
  selectedStatisticsZones: string[] | undefined;

  selectedDetailZones: string[] | undefined;
  selectedStatisticsDetailZones: string[] | undefined;
  selectedStatisticsDetailDates: string[] | undefined;
  selectedDates: string[] | undefined;
  selectedDetailSubjects: Tag[] | undefined;
  selectedDetailLanguages: string[] | undefined;
  selectedDetailFormats: string[] | undefined;
  selectedSearchTerm: string | undefined;
  isSingleCampaignLoading: boolean;
}

export const campaignStore: Module<CampaignState, RootState> = {
  namespaced: true,
  state: {
    campaigns: undefined,
    continuationToken: undefined,
    selectedCampaign: undefined,

    // tags
    allAvailableCampaignZones: undefined,
    allGeneralTags: undefined,
    allCampaignsZones: undefined,

    // selected filters camp overview
    selectedCampaignsZones: undefined,
    selectedDates: undefined,
    selectedGeneralTags: undefined,
    selectedSubjectTags: undefined,
    // selectedSubjects: undefined,
    selectedSearchTerm: undefined,

    // filters camp detail
    selectedDetailZones: undefined,
    selectedDetailLanguages: undefined,
    selectedDetailFormats: undefined,
    selectedDetailSubjects: undefined,

    // filters camp detail stats
    selectedStatisticsZones: undefined,
    selectedStatisticsDetailZones: undefined,
    selectedStatisticsDetailDates: undefined,

    isSingleCampaignLoading: false,
  },
  actions: {
    async setCampaigns(context) {
      const currentUser = context.rootState.userStore.currentUser;
      const subjectIds = _map(context.state.selectedSubjectTags, 'id');
      const dto = {
        zoneIds: context.state.selectedCampaignsZones,
        tagIds: context.state.selectedGeneralTags?.map((tag) => tag.id),
        subjectIds: subjectIds,
        startDate: context.state.selectedDates ? context.state.selectedDates[0] : undefined,
        endDate: context.state.selectedDates ? context.state.selectedDates[1] : undefined,
        search: context.state.selectedSearchTerm,
      };
      if (currentUser) {
        const { data, continuationToken } = await findCampaignsUseCase(currentUser, dto);
        context.commit('setCampaigns', data);
        context.commit('setContinuationToken', continuationToken);
      }
    },
    async loadMoreCampaigns(context) {
      const currentUser = context.rootState.userStore.currentUser;
      if (currentUser) {
        const subjectIds = _map(context.state.selectedSubjectTags, 'id');
        const { data, continuationToken } = await findCampaignsUseCase(currentUser, {
          continuationToken: context.state.continuationToken,
          zoneIds: context.state.selectedCampaignsZones,
          tagIds: context.state.selectedGeneralTags?.map((tag) => tag.id),
          subjectIds: subjectIds,
          endDate: context.state.selectedDates ? context.state.selectedDates[1] : undefined,
          startDate: context.state.selectedDates ? context.state.selectedDates[0] : undefined,
          search: context.state.selectedSearchTerm,
        });
        context.commit('addCampaigns', data);
        context.commit('setContinuationToken', continuationToken);
      }
    },
    // tags
    async setCampaignsZones(context, data: Tag[]) {
      context.commit('setFilterCampaignZones', data);
      await context.dispatch('setCampaigns');
    },
    async triggerSetSelectedCampaign(context, campaign: Campaign | undefined) {
      context.commit('setSelectedCampaign', campaign);
    },
    async triggerDeleteCampaign(context, campaignId: string) {
      context.commit('deleteCampaign', campaignId);
    },
  },

  mutations: {
    setStatisticsDetailZones(state, detailZones: string[]) {
      state.selectedStatisticsDetailZones = detailZones;
    },
    resetCampaignDetails(state) {
      state.selectedDetailZones = undefined;
      state.selectedDetailFormats = undefined;
      state.selectedDetailLanguages = undefined;
      state.selectedDetailSubjects = undefined;
    },
    resetCampaignStatisticsDetails(state) {
      state.selectedStatisticsDetailDates = undefined;
      state.selectedStatisticsDetailZones = undefined;
    },
    setDetailFormats(state, formats) {
      state.selectedDetailFormats = formats;
    },
    setDetailLanguages(state, languages) {
      state.selectedDetailLanguages = languages;
    },
    setDetailSubjects(state, tags) {
      state.selectedDetailSubjects = tags;
    },
    setStatisticsDetailDates(state, dates) {
      state.selectedStatisticsDetailDates = dates;
    },
    setFilterDetailZones(state, detailZones: string[]) {
      state.selectedDetailZones = detailZones;
    },
    setCampaigns(state, campaigns: Campaign[]) {
      state.campaigns = campaigns;
    },
    setContinuationToken(state, token: number) {
      state.continuationToken = token;
    },
    addCampaigns(state, campaigns: Campaign[]) {
      if (state.campaigns) {
        state.campaigns = [...state.campaigns, ...campaigns];
      }
    },
    // tags
    setAllAvailableCampaignZones(state, tags: Tag[]) {
      state.allAvailableCampaignZones = tags;
    },
    setCampaignsZones(state, tags: Tag[]) {
      state.allCampaignsZones = tags;
    },
    setFilterGeneralTags(state, tags: Tag[]) {
      state.selectedGeneralTags = tags;
    },
    setFilterCampaignZones(state, tags: string[]) {
      state.selectedCampaignsZones = tags;
    },
    setFilterStatisticsZones(state, tags: string[]) {
      state.selectedStatisticsZones = tags;
    },
    setFilterDates(state, dates: string[]) {
      state.selectedDates = dates;
    },
    setFilterSubjectTags(state, tags: Tag[]) {
      state.selectedSubjectTags = tags;
    },
    setFilterSearch(state, term: string) {
      state.selectedSearchTerm = term;
    },
    setSelectedCampaign(state, campaign: Campaign | undefined) {
      state.selectedCampaign = campaign;
    },
    deleteCampaign(state, campaignId) {
      const index = state.campaigns?.findIndex((c: Campaign) => c.id === campaignId);
      if (index && index > -1) {
        state.campaigns?.splice(index, 1);
      }
    },
    resetAllFilters(state) {
      state.selectedGeneralTags = undefined;
      state.selectedCampaignsZones = undefined;
      state.selectedStatisticsZones = undefined;
      state.selectedStatisticsDetailDates = undefined;
      state.selectedDetailZones = undefined;
      state.selectedDates = undefined;
      state.selectedSubjectTags = undefined;
      state.selectedDetailSubjects = undefined;
      state.selectedSearchTerm = undefined;
      state.selectedDetailLanguages = undefined;
      state.selectedDetailFormats = undefined;
    },
  },

  getters: {
    getSelectedCampaign: (state: CampaignState): Campaign | undefined => state.selectedCampaign,
    hasSelectedFilters: (state: CampaignState): boolean =>
      !!state.selectedCampaignsZones ||
      !!state.selectedDates ||
      !!state.selectedGeneralTags ||
      !!state.selectedSubjectTags ||
      !!state.selectedSearchTerm,
  },
};
