import 'vue-class-component/hooks';
import 'reflect-metadata';

import Vue from 'vue';
import router from '@/ui/router';
import store from '@/ui/store';
import App from '@/ui/App.vue';
import i18n from '@/i18n';

import { injectDependencies } from '@/dependencyInjection';
import { container } from 'tsyringe';
import vuetify from '@/plugins/vuetify';
import HighchartsVue from 'highcharts-vue';
import { TagValue } from '@/plugins/tagValue';
import { errorHandler } from '@/infrastructure/error/ErrorHandler';
import { installFilters } from './ui/util/filters';

Vue.use(HighchartsVue);
Vue.use(TagValue);
Vue.use(installFilters);

Vue.config.productionTip = false;

injectDependencies();

Vue.config.productionTip = false;
Vue.prototype.$container = container;

Vue.config.errorHandler = errorHandler;

new Vue({
  router,
  store,
  vuetify,
  i18n,
  render: (h) => h(App),
}).$mount('#app');
