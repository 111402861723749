export enum Languages {
  DE = 'de-CH',
  FR = 'fr-CH',
  IT = 'it-CH',
  EN = 'en-CH',
}
export function getTranslationIdentifierFromLanguages(language: Languages): string {
  switch (language) {
    case Languages.DE:
      return 'Admin.create-user.language-german';
    case Languages.EN:
      return 'Admin.create-user.language-english';
    case Languages.FR:
      return 'Admin.create-user.language-french';
    case Languages.IT:
      return 'Admin.create-user.language-italian';
  }
}
