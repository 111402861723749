










import { Component, Prop, Vue } from 'vue-property-decorator';

@Component({})
export default class LabelChip extends Vue {
  @Prop({ default: null }) labelText?: string;
  @Prop({ default: true }) showMultiLangIcon!: boolean;
  @Prop({ default: true }) showClosingIcon!: boolean;

  emitClosedClick() {
    this.$emit('closedClick');
  }
  emitMultiLanguagesClick() {
    this.$emit('multiLanguagesClick');
  }
}
