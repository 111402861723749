import { container } from 'tsyringe';
import { AdminService } from '@/core/admin/services/AdminServices';
import type { CurrentUser } from '@/core/user/models/CurrentUser';
import { VersionMetadata } from '../models/VersionMetadata';

export const getVersionUseCase = async (currentUser: CurrentUser): Promise<VersionMetadata> => {
  const adminService = container.resolve<AdminService>('AdminService');

  return await adminService.getVersion(currentUser);
};
