import { inject, injectable } from 'tsyringe';
import { HttpService } from '@/infrastructure/http/HttpService';
import type { CurrentUser } from '@/core/user/models/CurrentUser';
import { API_PATH } from '@/infrastructure/enum/ApiPath';
import { StatisticsService } from '@/core/statistics/services/StatisticsService';
import { GenericResponse } from '@/infrastructure/models/Response';
import { GeneralStatistic, BaseStatistic } from '@/core/statistics/models/Statistics';
import { CancelToken } from 'axios';

@injectable()
export class HttpStatisticsService implements StatisticsService {
  constructor(
    @inject(HttpService)
    private readonly httpService: HttpService,
  ) {}

  async getCampaignStatistic(
    currentUser: CurrentUser,
    campaignId?: string,
    startDate?: string,
    endDate?: string,
    zoneIds?: string[],
  ): Promise<BaseStatistic> {
    const endpoint = `${API_PATH.CUSTOMER}/${currentUser.customerId}/${currentUser.id}/${API_PATH.MEASUREMENTS}/${campaignId}`;
    const response = await this.httpService.post<GenericResponse<BaseStatistic>>(endpoint, {
      startDate,
      endDate,
      zoneIds,
    });
    return response?.result?.data;
  }

  async getGeneralStatistic(
    currentUser: CurrentUser,
    customerId: string,
    startDate?: string,
    endDate?: string,
    zoneIds?: string[],
    cancelToken?: CancelToken,
  ): Promise<GeneralStatistic> {
    const endpoint = `${API_PATH.CUSTOMER}/${currentUser.customerId}/${currentUser.id}/${API_PATH.MEASUREMENTS}`;
    const response = await this.httpService.post<GenericResponse<GeneralStatistic>>(
      endpoint,
      {
        customerId,
        startDate,
        endDate,
        zoneIds,
      },
      undefined,
      cancelToken,
    );
    return response?.result?.data;
  }
}
