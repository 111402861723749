import i18n from '@/i18n';
import getLogger from '@/shared/logger/logger';

const LOG = getLogger('AlertHelper');

function getTranslationIdentifierFromStatus(status: number): string {
  if (isStatusMessageDefined(status)) {
    return `shared.notification.message-error-${status.toString()}`;
  } else {
    return `shared.notification.message-error-default`;
  }
}

function isStatusMessageDefined(status: number): boolean {
  const definedStatuses = getAllDefinedStatuses();
  return definedStatuses.includes(status);
}

function getAllDefinedStatuses(): number[] {
  const definedStatuses: Array<number> = [];
  const definedStatusMessages = getAllDefinedStatusMessages();
  for (const definedMessage of Object.keys(definedStatusMessages)) {
    try {
      definedStatuses.push(stripIdentifierForStatus(definedMessage));
    } catch (e) {
      continue;
    }
  }
  return definedStatuses;
}

function getAllDefinedStatusMessages(): string[] {
  let definedStatuses = [];
  try {
    definedStatuses = i18n.i18next.store.data[getCurrentLanguage()].translation.shared.notification;
  } catch (e) {
    LOG.warn('Could not find Alert Status translations');
  }
  return definedStatuses;
}

function stripIdentifierForStatus(identifier: string): number {
  const split = identifier.split('-');
  const statusCode = Number(split[split.length - 1]);
  if (isNaN(statusCode)) {
    throw new Error('Not a status code');
  } else {
    return statusCode;
  }
}

function getCurrentLanguage(): string {
  return i18n.i18next.options.lng;
}
export { getTranslationIdentifierFromStatus };
