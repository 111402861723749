import axios, { AxiosError, AxiosInstance, AxiosRequestConfig, AxiosResponse, CancelToken } from 'axios';
import { MsalAuthService } from '@/infrastructure/auth/MsalAuthService';
import { AlertStoreActions, AlertStoreMutations, AlertType } from '@/ui/stores/AlertStore';
import store from '@/ui/store';
import { HttpStoreMutations } from '@/ui/stores/HttpStore';
import { getAppInsights } from '@/infrastructure/error/InsightsService';
import axiosRetry from 'axios-retry';

interface StringMap {
  [key: string]: string;
}

const ALLOWED_TO_RETRY_POST_REGEXES = [
  /^customer\/[0-9a-fA-F]{8}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{12}\/[0-9a-fA-F]{8}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{12}\/tag\/find$/i,
];
export class HttpService {
  private client: AxiosInstance;
  private authService = new MsalAuthService();
  private appInsights = getAppInsights();

  constructor(baseUrl: string) {
    this.client = axios.create({
      baseURL: baseUrl,
    });
    axiosRetry(this.client, {
      retries: 3,
      retryDelay: axiosRetry.exponentialDelay,
      shouldResetTimeout: true,
      retryCondition: (error: AxiosError): boolean => {
        return axiosRetry.isNetworkOrIdempotentRequestError(error) || this.isEnabledPostEndpoint(error.config);
      },
    });
    this.initializeRequestInterceptor();
    this.initializeResponseInterceptor();
  }

  private initializeRequestInterceptor = () => {
    this.client.interceptors.request.use(this.handleRequest, this.handleError);
  };

  private initializeResponseInterceptor = () => {
    this.client.interceptors.response.use(this.handleResponse, this.handleError);
  };

  private handleRequest = async (config: AxiosRequestConfig) => {
    let axiosSource;
    if (!config.cancelToken) {
      axiosSource = axios.CancelToken?.source();
      config.cancelToken = axiosSource?.token;
    }
    store.commit(HttpStoreMutations.ADD_REQUEST, { ...config, cancel: axiosSource?.cancel });
    config.headers = config.headers ?? {};
    config.headers['Authorization'] = await this.setAuthenticationToken();
    return config;
  };

  private handleResponse = ({ data }: AxiosResponse) => data;

  protected handleError = (error: AxiosError) => {
    if (!axios.isCancel(error)) {
      this.appInsights.trackException({ error: error });
      store.dispatch(AlertStoreActions.TRIGGER_HTTP_ALERT, {
        statusCode: error.request?.status,
        type: AlertType.ERROR,
      });
    }
    store.commit(AlertStoreMutations.SET_IS_LOADING, false);
  };

  private async setAuthenticationToken(): Promise<string> {
    const authToken = await this.authService.acquireToken();
    return `Bearer ${authToken}`;
  }

  async get<S>(endpoint: string, params?: StringMap, headers?: StringMap): Promise<S> {
    return await this.client.get(endpoint, {
      params,
      headers,
    });
  }

  async post<S>(endpoint: string, data?: unknown, headers?: StringMap, cancelToken?: CancelToken): Promise<S> {
    return this.client.post(endpoint, data, {
      headers,
      cancelToken,
    });
  }

  async patch<S>(endpoint: string, data?: unknown, headers?: StringMap): Promise<S> {
    return this.client.patch(endpoint, data, {
      headers,
    });
  }

  async delete<S>(endpoint: string, params?: StringMap, headers?: StringMap): Promise<S> {
    return this.client.delete(endpoint, {
      params,
      headers,
    });
  }

  private isEnabledPostEndpoint(config: AxiosRequestConfig): boolean {
    return this.isPostMethod(config.method) && this.isUrlAllowed(config);
  }

  private isUrlAllowed(config: AxiosRequestConfig): boolean {
    return ALLOWED_TO_RETRY_POST_REGEXES.some((regexUrl: RegExp) => {
      return regexUrl.test(<string>config.url);
    });
  }

  private isPostMethod(method: string | undefined): boolean {
    return method?.toUpperCase() === 'POST';
  }
}
