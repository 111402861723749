import { container } from 'tsyringe';
import store from '@/ui/store';
import type { Customer } from '@/core/customer/models/Customer';
import { CustomerStoreMutations } from '@/ui/stores/CustomerStore';
import { CustomerService } from '@/core/customer/services/CustomerService';

export const getCustomerUseCase = async (userId: string): Promise<Customer> => {
  const customerService = container.resolve<CustomerService>('CustomerService');
  const customer = await customerService.getCustomer(userId);
  store.commit(CustomerStoreMutations.SET_CUSTOMER, customer);
  return customer;
};
