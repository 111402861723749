import { RouteConfig } from 'vue-router';
import { ROUTE_NAMES } from '@/ui/constant/routes.constants';
import { USER_ROLE } from '@/core/user/enums/UserRole';

const PATHS: Record<string, string> = {
  ADMIN: '/admin',
  ADMIN_USERS: '/admin/users',
  ADMIN_CAMPAIGNS: '/admin/campaigns',
  ADMIN_LABELS: '/admin/labels',
  ADMIN_VERSION: '/admin/version',
};

export const ROUTE_ADMIN_USERS: RouteConfig = {
  path: PATHS.ADMIN_USERS,
  name: ROUTE_NAMES.ADMIN_USERS,
  component: () => import('@/ui/components/organisms/tabs/AdminUserTab.vue'),
  props: true,
  meta: {
    authorizedUserRoles: [USER_ROLE.SUPER_ADMIN, USER_ROLE.ADMIN],
  },
};

export const ROUTE_ADMIN_CAMPAIGNS: RouteConfig = {
  path: PATHS.ADMIN_CAMPAIGNS,
  name: ROUTE_NAMES.ADMIN_CAMPAIGNS,
  component: () => import('@/ui/components/organisms/tabs/AdminCampaignTab.vue'),
  props: true,
  meta: {
    authorizedUserRoles: [USER_ROLE.SUPER_ADMIN, USER_ROLE.ADMIN],
  },
};

export const ROUTE_ADMIN_LABELS: RouteConfig = {
  path: PATHS.ADMIN_LABELS,
  name: ROUTE_NAMES.ADMIN_LABELS,
  component: () => import('@/ui/components/organisms/tabs/AdminMandatoryLabelsTab.vue'),
  props: true,
  meta: {
    authorizedUserRoles: [USER_ROLE.SUPER_ADMIN, USER_ROLE.ADMIN],
  },
};

export const ROUTE_ADMIN_VERSION: RouteConfig = {
  path: PATHS.ADMIN_VERSION,
  name: ROUTE_NAMES.ADMIN_VERSION,
  component: () => import('@/ui/components/organisms/tabs/AdminVersionTab.vue'),
  props: true,
  meta: {
    authorizedUserRoles: [USER_ROLE.SUPER_ADMIN],
  },
};

export const ROUTE_ADMIN: RouteConfig = {
  path: PATHS.ADMIN,
  name: ROUTE_NAMES.ADMIN,
  component: () => import('@/ui/views/AdminView.vue'),
  redirect: ROUTE_ADMIN_USERS,
  meta: {
    authorizedUserRoles: [USER_ROLE.SUPER_ADMIN, USER_ROLE.ADMIN],
  },
  children: [ROUTE_ADMIN_USERS, ROUTE_ADMIN_CAMPAIGNS, ROUTE_ADMIN_LABELS, ROUTE_ADMIN_VERSION],
};
