export const IMAGE_FILE_SUFFIXES = [
  '.avif',
  '.gif',
  '.apng',
  '.png',
  '.jpg',
  '.jpeg',
  '.jfif',
  '.pjpeg',
  '.pjp',
  '.webp',
  '.svg',
];
