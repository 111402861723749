

















































import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
import getLogger from '@/shared/logger/logger';
import i18n from '@/i18n';
import type { LanguageObject } from '@/core/campaign/models/LanguageObject';
import ButtonWithText from '@/ui/components/atoms/buttons/ButtonWithText.vue';
import { groupBy as _groupBy } from 'lodash';
import { CampaignMedia } from '@/core/campaign/models/CampaignMedia';
import type { Tag } from '@/core/tags/models/Tag';
import OverflowChip from '@/ui/components/atoms/groupingItems/OverflowChip.vue';
import LabelChip from '@/ui/components/atoms/groupingItems/LabelChip.vue';
import { getEmptyCampaignTag } from '@/ui/services/tags/tagService';
import { TagType } from '@/core/tags/enums/TagType';

/* eslint-disable-next-line @typescript-eslint/no-unused-vars */
const LOG = getLogger('ChannelFilter');

@Component({
  name: 'ChannelFilter',
  components: { ButtonWithText, LabelChip, OverflowChip },
})
export default class ChannelFilter extends Vue {
  @Prop({ default: undefined }) campaignMedia: CampaignMedia[] | undefined;
  @Prop() zones!: Tag[];
  @Prop() selectedZones!: Tag[];
  private showMenu = false;
  private readonly locale: keyof LanguageObject = i18n.i18next.language;
  private numberOfFilterTags = 3;
  private temporarySelectedZones: Tag[] = [];

  mounted() {
    this.onSelectedZonesUpdated();
  }

  @Watch('selectedZonesUpdated')
  onSelectedZonesUpdated() {
    this.temporarySelectedZones = this.selectedZonesUpdated;
  }

  get selectedZonesUpdated() {
    return this.selectedZones || [];
  }

  get channelTree() {
    const groupedZonesByChannel = _groupBy(this.zones, `group.value[${this.locale}]`);
    const channelNames = Object.keys(groupedZonesByChannel);
    return Object.values(groupedZonesByChannel).map((zones, index) => {
      return {
        name: channelNames[index],
        id: channelNames[index],
        children: this.getMappedZones(zones),
      };
    });
  }

  removeTag(index: number) {
    let temporarySelectedZones = this.temporarySelectedZones;
    temporarySelectedZones.splice(index, 1);
    this.updateParent(temporarySelectedZones);
  }

  getMappedZones(zones: Tag[]) {
    return zones.map((zone) => {
      return {
        id: zone.id,
        name: this.$tagValue(zone),
      };
    });
  }

  resetFilter() {
    this.updateParent([]);
  }

  updateParent(updatedZones: Tag[]) {
    this.$emit('updated', updatedZones);
    this.showMenu = false;
  }

  getTagsFromID(selectedIds: string[]) {
    return selectedIds
      .map((id) => {
        if (this.zones?.length > 0) {
          return this.zones.find((zone: Tag) => zone.id == id);
        } else {
          return getEmptyCampaignTag(TagType.ZONE);
        }
      })
      .slice(0, this.numberOfFilterTags);
  }
}
