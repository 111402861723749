import Vue from 'vue';
import Vuetify from 'vuetify/lib';

Vue.use(Vuetify);

export default new Vuetify({
  theme: {
    themes: {
      light: {
        primary: '#fff',
        secondary: '#2B2B2B',
        accent: '#E60100',
        error: '#FF5252',
        info: '#2196F3',
        success: '#4CAF50',
        warning: '#FB8C00',
        blueGray: '#8c99ad',
        alertError: '#FF5252',
        alertWarning: '#FB8C00',
        alertSuccess: '#4CAF50',
        alertNotSet: '#00b8fe',
      },
    },
    options: { customProperties: true },
  },
});
