export class Timing {
  _seconds: number;
  constructor(seconds: number) {
    this._seconds = seconds;
  }

  get SECONDS(): number {
    return this._seconds;
  }

  get MILLISECONDS(): number {
    return this._seconds * 1000;
  }
}

export const timing = { SUCCESS_TIMEOUT: new Timing(6.5) };
