import { container } from 'tsyringe';
import type { User } from '@/core/admin/models/User';
import { AdminService } from '@/core/admin/services/AdminServices';
import type { CurrentUser } from '@/core/user/models/CurrentUser';

export const getAllUsersUseCase = async (user: CurrentUser): Promise<User[]> => {
  const adminService = container.resolve<AdminService>('AdminService');

  return await adminService.getAllUsers(user);
};
