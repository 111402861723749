import { Module } from 'vuex';
import { RootState } from '@/ui/store';
import type { Campaign } from '@/core/campaign/models/Campaign';
import { findCampaignsUseCase } from '@/core/campaign/usecases/findCampaignsUseCase';
import { findTagsUseCase } from '@/core/tags/usecases/findTagsUseCase';
import type { Tag } from '@/core/tags/models/Tag';
import { TagType } from '@/core/tags/enums/TagType';
import type { User } from '@/core/admin/models/User';
import { getAllUsersUseCase } from '@/core/admin/usecases/getAllUsersUseCase';
import { map as _map } from 'lodash';
import { VersionMetadata } from '@/core/admin/models/VersionMetadata';
import { getVersionUseCase } from '@/core/admin/usecases/getVersionUseCase';

export const AdminStoreGetters = {
  GET_IS_ADMIN_CREATE_CAMPAIGN: 'adminStore/getIsAdminCreateCampaign',
  GET_IS_CAMPAIGN_EDIT: 'adminStore/getIsCampaignInEdit',
  GET_CAMPAIGN_TO_EDIT: 'adminStore/getCampaignToEdit',
};

export const AdminStoreMutations = {
  SET_IS_ADMIN_CREATE_CAMPAIGN: 'adminStore/setIsAdminCreateCampaign',
  SET_CAMPAIGN_TO_EDIT: 'adminStore/setCampaignToEdit',
  RESET_CAMPAIGN_TO_EDIT: 'adminStore/resetCampaignToEdit',
  SET_CAMPAIGNS: 'adminStore/setCampaigns',
  LOAD_MORE_CAMPAIGNS: 'adminStore/loadMoreCampaigns',
  SET_IS_NEW_USER_CREATED: 'adminStore/setIsNewUserCreated',

  // filter tags
  SET_ALL_ZONES: 'adminStore/setAllZones',
  SET_FILTER_SEARCH: 'adminStore/setSearchTerm',
  SET_FILTER_GENERAL_TAGS: 'adminStore/setGeneralTags',
  SET_FILTER_SUBJECT_TAGS: 'adminStore/setFilterSubjectTags',
  SET_FILTER_ADMINISTRATIVE_ZONES: 'adminStore/setAdministrativeZones',
  SET_FILTER_DATES: 'adminStore/setDates',
  RESET_ALL_FILTERS: 'adminStore/resetAllFilters',

  SET_TAGS_BY_TYPE: 'adminStore/setTagsByType',
};

export const AdminStoreActions = {
  GET_FILTER_ADMINISTRATIVE_ZONES: 'adminStore/getAdministrativeZones',
};

export interface AdminState {
  isAdminCreateCampaign: boolean;
  campaignToEdit?: Campaign;
  campaigns?: Campaign[];
  continuationToken?: number;
  isNewUserCreated: boolean;

  users?: User[];
  tags: {
    [TagType.CHANNEL]: Tag[];
    [TagType.ZONE]: Tag[];
    [TagType.LANGUAGE]: Tag[];
    [TagType.FORMAT]: Tag[];
  };

  // filter tags
  allGeneralTags?: Tag[];
  allZones?: Tag[];
  selectedGeneralTags?: Tag[];
  selectedAdministrativeZones?: string[];
  selectedDates?: string[];
  selectedSubjectTags?: Tag[];
  selectedSearchTerm?: string;

  // version metadata
  buildVersion?: string;
  buildTimestamp?: string;
  buildStage?: string;
  environmentName?: string;

  lastRefresh?: Date;
}

export const adminStore: Module<AdminState, RootState> = {
  namespaced: true,
  state: {
    isAdminCreateCampaign: false,
    campaignToEdit: undefined,

    campaigns: undefined,
    continuationToken: undefined,
    isNewUserCreated: false,

    // filter tags
    allGeneralTags: undefined,
    allZones: undefined,
    selectedGeneralTags: undefined,
    selectedAdministrativeZones: undefined,
    selectedDates: undefined,
    selectedSubjectTags: undefined,
    selectedSearchTerm: undefined,

    lastRefresh: undefined,
    users: undefined,
    tags: {
      [TagType.CHANNEL]: [],
      [TagType.ZONE]: [],
      [TagType.LANGUAGE]: [],
      [TagType.FORMAT]: [],
    },

    // version metadata
    buildVersion: undefined,
    buildTimestamp: undefined,
    buildStage: undefined,
    environmentName: undefined,
  },
  actions: {
    async setCampaigns(context) {
      context.state.lastRefresh = new Date();
      const currentUser = context.rootState.userStore.currentUser;
      const subjectIds = _map(context.state.selectedSubjectTags, 'id');
      const dto = {
        zoneIds: context.state.selectedAdministrativeZones,
        tagIds: context.state.selectedGeneralTags?.map((tag) => tag.id),
        subjectIds: subjectIds,
        startDate: context.state.selectedDates ? context.state.selectedDates[0] : undefined,
        endDate: context.state.selectedDates ? context.state.selectedDates[1] : undefined,
        search: context.state.selectedSearchTerm,
      };
      if (currentUser) {
        const { data, continuationToken } = await findCampaignsUseCase(currentUser, dto);
        context.commit('setCampaigns', data);
        context.commit('setContinuationToken', continuationToken);
      }
    },
    async reloadCampaigns(context) {
      context.state.lastRefresh = new Date();
      context.state.campaigns = undefined;
      await context.dispatch('setCampaigns');
    },
    async loadMoreCampaigns(context) {
      const currentUser = context.rootState.userStore.currentUser;
      if (currentUser) {
        const subjectIds = _map(context.state.selectedSubjectTags, 'id');
        const { data, continuationToken } = await findCampaignsUseCase(currentUser, {
          continuationToken: context.state.continuationToken,
          zoneIds: context.state.selectedAdministrativeZones,
          tagIds: context.state.selectedGeneralTags?.map((tag) => tag.id),
          subjectIds: subjectIds,
          startDate: context.state.selectedDates ? context.state.selectedDates[0] : undefined,
          endDate: context.state.selectedDates ? context.state.selectedDates[1] : undefined,
          search: context.state.selectedSearchTerm,
        });
        context.commit('addCampaigns', data);
        context.commit('setContinuationToken', continuationToken);
      }
    },
    async getAdministrativeZones(context) {
      const currentUser = context.rootState.userStore.currentUser;
      if (currentUser) {
        const tags = await findTagsUseCase({ type: TagType.ZONE }, currentUser);
        context.commit('setAllZones', tags);
      }
    },
    async getAllUsers(context) {
      context.state.lastRefresh = new Date();
      const currentUser = context.rootState.userStore.currentUser;
      if (currentUser) {
        const users = await getAllUsersUseCase(currentUser);
        context.commit('setUsers', users);
      }
    },
    async reloadUsers(context) {
      context.state.users = undefined;
      await context.dispatch('getAllUsers');
    },
    async getAllTags(context) {
      context.state.lastRefresh = new Date();
      await context.dispatch('getTagsByType', TagType.CHANNEL);
      await context.dispatch('getTagsByType', TagType.ZONE);
      await context.dispatch('getTagsByType', TagType.LANGUAGE);
      await context.dispatch('getTagsByType', TagType.FORMAT);
    },
    async getTagsByType(context, type: TagType) {
      if (type === TagType.SUBJECT || type === TagType.GENERAL) {
        return;
      }
      const currentUser = context.rootState.userStore.currentUser;
      if (currentUser) {
        const tags = await findTagsUseCase({ type }, currentUser);
        context.state.tags[type] = tags;
      }
    },
    async reloadTags(context) {
      context.state.tags = { [TagType.CHANNEL]: [], [TagType.ZONE]: [], [TagType.LANGUAGE]: [], [TagType.FORMAT]: [] };
      await context.dispatch('getAllTags');
    },
    async getVersion(context) {
      context.state.lastRefresh = new Date();
      const currentUser = context.rootState.userStore.currentUser;
      if (currentUser) {
        const version = await getVersionUseCase(currentUser);
        context.commit('setVersion', version);
      }
    },
    async reloadVersion(context) {
      await context.dispatch('getVersion');
    },
  },
  mutations: {
    setIsAdminCreateCampaign(state: AdminState, isAdminCreateCampaign: boolean) {
      state.isAdminCreateCampaign = isAdminCreateCampaign;
    },
    setCampaignToEdit(state: AdminState, campaignToEdit: Campaign) {
      state.campaignToEdit = campaignToEdit;
    },
    resetCampaignToEdit(state: AdminState) {
      state.campaignToEdit = undefined;
    },
    setCampaigns(state, campaigns: Campaign[]) {
      state.campaigns = campaigns;
    },
    setContinuationToken(state, token: number) {
      state.continuationToken = token;
    },
    addCampaigns(state, campaigns: Campaign[]) {
      if (state.campaigns) {
        state.campaigns = [...state.campaigns, ...campaigns];
      }
    },
    setIsNewUserCreated(state, isNewUserCreated: boolean) {
      state.isNewUserCreated = isNewUserCreated;
    },
    // filter tags
    setAllZones(state, tags: Tag[]) {
      state.allZones = tags;
    },
    setSearchTerm(state, term: string) {
      state.selectedSearchTerm = term;
    },
    setGeneralTags(state, tags: Tag[]) {
      state.selectedGeneralTags = tags;
    },
    setAdministrativeZones(state, tags: string[]) {
      state.selectedAdministrativeZones = tags;
    },
    setDates(state, dates: string[]) {
      state.selectedDates = dates;
    },
    setFilterSubjectTags(state, tags: Tag[]) {
      state.selectedSubjectTags = tags;
    },
    setUsers(state, users: User[]) {
      state.users = users;
    },
    setVersion(state, version: VersionMetadata) {
      state.buildVersion = version.build.version;
      state.buildTimestamp = version.build.timestamp;
      state.buildStage = version.build.stage;
      state.environmentName = version.environment.name;
    },
    resetAllFilters(state) {
      state.selectedGeneralTags = undefined;
      state.selectedAdministrativeZones = undefined;
      state.selectedDates = undefined;
      state.selectedSubjectTags = undefined;
      state.selectedSearchTerm = undefined;
    },
  },
  getters: {
    getIsAdminCreateCampaign(state: AdminState): boolean {
      return state.isAdminCreateCampaign;
    },
    getIsCampaignInEdit(state: AdminState): boolean {
      return Boolean(state.campaignToEdit);
    },
    getCampaignToEdit(state: AdminState): Campaign | undefined {
      return state.campaignToEdit;
    },
  },
};
