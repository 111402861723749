export enum SettingType {
  CUSTOMER_PREFERENCES = 'customer_preferences',
}

export enum AppThemeType {
  DEFAULT_BLUE = 'Default Blue',
  DEFAULT_UBS = 'Default UBS',
}

export const APP_THEME_TYPES: AppThemeType[] = [AppThemeType.DEFAULT_BLUE, AppThemeType.DEFAULT_UBS];

export interface CustomerPreferences {
  appTitle?: string;
  appTheme?: AppThemeType;
}

export interface Setting<T> {
  type: SettingType;
  content: T;
  customerId: string;
}
