













import { Component, Prop, Vue } from 'vue-property-decorator';
import getLogger from '@/shared/logger/logger';
import { Campaign, TopCampaign } from '@/core/campaign/models/Campaign';
import { CampaignMedia, StoredFile } from '@/core/campaign/models/CampaignMedia';
import { IMAGE_FILE_SUFFIXES } from '@/ui/constant/media.constant';
import { MediumStatus, Orientation } from '@/core/campaign/enums/Campaign';
import { filterAndSortThumbnails } from '@/ui/util/thumbnail';
import { formatDate } from '@/ui/util/date';
import { playoutFormat } from '@/ui/util/numberFormat';
import store from '@/ui/store';
import { CustomerStoreGetters } from '@/ui/stores/CustomerStore';
import { AppThemeType } from '@/core/shared/model/Setting';

/* eslint-disable-next-line @typescript-eslint/no-unused-vars */
const LOG = getLogger('CampaignCard');

@Component({
  name: 'CampaignCard',
  computed: {
    storePreferredTheme: (): string | undefined => store.getters[CustomerStoreGetters.GET_PREFERRED_THEME],
  },
})
export default class CampaignCard extends Vue {
  @Prop() campaign!: Campaign | TopCampaign;
  private dateType = 'date';
  private storePreferredTheme: string | undefined;

  get startDate() {
    return formatDate(this.campaign?.startDate, this.dateType);
  }

  get endDate() {
    return formatDate(this.campaign?.endDate, this.dateType);
  }

  get defaultImage() {
    return this.storePreferredTheme === AppThemeType.DEFAULT_UBS
      ? require('@/ui/assets/thumbnail/image-empty-ubs.svg')
      : require('@/ui/assets/thumbnail/image-empty.svg');
  }

  get source() {
    return this.campaign.media?.length > 0 && this.mediaHasThumbnailIndex
      ? this.thumbnail
      : this.mediaIsImage
      ? this.firstMediaFilePath
      : this.defaultImage;
  }

  get firstMediaFile() {
    return this.campaign.media?.[0].files?.[0];
  }

  get firstMediaFilePath() {
    return this.firstMediaFile.storagePath;
  }

  get mediaIsImage() {
    return this.isImageFile(this.firstMediaFile);
  }

  get thumbnail() {
    return this.isImageFile(this.landscapeThumbnail) || this.isImageFile(this.firstThumbnail);
  }

  get firstThumbnail() {
    return filterAndSortThumbnails(this.campaign.media.flatMap((medium) => medium.thumbnails))?.[0];
  }

  get landscapeThumbnail() {
    const medium = this.campaign.media?.find(
      (medium: CampaignMedia) =>
        medium.status !== MediumStatus.DELETED &&
        medium?.thumbnails?.length > 0 &&
        medium?.format?.value['en-CH'] === Orientation.LANDSCAPE,
    );
    return filterAndSortThumbnails(medium?.thumbnails)?.[0];
  }

  get mediaHasThumbnailIndex() {
    return this.campaign.media?.some((media) => media.thumbnails?.find((file) => this.isImageFile(file)));
  }

  get playouts() {
    const campaign = this.campaign as TopCampaign;
    if (campaign.playouts) {
      return playoutFormat(campaign.playouts);
    }
    return undefined;
  }

  isImageFile(file: StoredFile | undefined) {
    return IMAGE_FILE_SUFFIXES.some((suffix) => file?.storagePath?.endsWith(suffix)) ? file?.storagePath : undefined;
  }
}
