































































import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
import LabelChip from '@/ui/components/atoms/groupingItems/LabelChip.vue';
import getLogger from '@/shared/logger/logger';
import ButtonWithText from '@/ui/components/atoms/buttons/ButtonWithText.vue';
import type { Tag } from '@/core/tags/models/Tag';
import OverflowChip from '@/ui/components/atoms/groupingItems/OverflowChip.vue';
import { INPUT } from '@/ui/constant/input.const';

/* eslint-disable-next-line @typescript-eslint/no-unused-vars */
const LOG = getLogger('LabelFilter');

@Component({
  name: 'LabelFilter',
  components: { ButtonWithText, LabelChip, OverflowChip },
  computed: {},
})
export default class LabelFilter extends Vue {
  @Prop() private items!: Tag[];
  @Prop() selectedFilter!: Tag[];
  @Prop({ default: 300 }) searchDebounce!: number;
  private temporarySelected: Tag[] = [];
  private showMenu = false;
  private search = '';
  private numberOfFilterTags = 3;
  debounceTimeout: number | undefined;

  mounted() {
    this.temporarySelected = this.selectedFilter;
  }

  @Watch('selectedFilter')
  onReset(val: Tag[]) {
    if (!val || val.length === 0) {
      this.resetFilter();
    }
  }

  @Watch('searchInput')
  onSearch(val: string[]) {
    if (val && val.length >= INPUT.MIN_SUGGESTION_STRING_LENGTH) {
      if (this.debounceTimeout) {
        clearTimeout(this.debounceTimeout);
      }

      this.debounceTimeout = setTimeout(() => {
        this.$emit('search-string', val);
        clearTimeout(this.debounceTimeout);
        this.debounceTimeout = undefined;
      }, this.searchDebounce);
    } else {
      this.$emit('search-string', '');
    }
  }

  get predefinedItems() {
    const search = this.search.toLowerCase();
    if (!search || search.length < INPUT.MIN_SUGGESTION_STRING_LENGTH) return [];
    return this.items.filter((item) => {
      const text = this.$tagValue(item)?.toLowerCase();

      return text.indexOf(search) > -1;
    });
  }

  get searchInput() {
    return this.search.toLowerCase();
  }

  updateParent() {
    this.$emit('updated', this.temporarySelected);
    this.showMenu = false;
  }

  addSelectedItem(item: Tag) {
    this.temporarySelected.push(item);
    this.search = '';
  }

  removeLabel(index: number) {
    this.temporarySelected.splice(index, 1);
    this.updateParent();
  }

  resetFilter() {
    this.temporarySelected = [];
  }

  isSelected(item: Tag) {
    return !!this.temporarySelected.find((selectedItem) => selectedItem.id === item.id);
  }
}
