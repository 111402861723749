import type { Customer } from '@/core/customer/models/Customer';
import { Module } from 'vuex';
import { RootState } from '@/ui/store';
import { AppThemeType, CustomerPreferences, Setting, SettingType } from '@/core/shared/model/Setting';

export const CustomerStoreMutations = {
  SET_CUSTOMERS: 'customerStore/setCustomers',
  SET_CUSTOMER: 'customerStore/setCustomer',
  SET_CUSTOMER_PREFERENCES: 'customerStore/setCustomerPreferences',
  SET_CUSTOMER_PREFERENCES_APP_TITLE_AND_APP_THEME: 'customerStore/setCustomerPreferencesAppTitleAndAppTheme',
};

export const CustomerStoreGetters = {
  GET_PREFERRED_TITLE: 'customerStore/getPreferredTitle',
  GET_CUSTOMER_PREFERENCES: 'customerStore/getPreferences',
  GET_PREFERRED_THEME: 'customerStore/getPreferredTheme',
};

export interface CustomerState {
  customers: Customer[] | undefined;
  customer: Customer | undefined;
  customerPreferences: Setting<CustomerPreferences> | undefined;
}

export const customerStore: Module<CustomerState, RootState> = {
  namespaced: true,
  state: {
    customers: undefined,
    customer: undefined,
    customerPreferences: undefined,
  },
  mutations: {
    setCustomers(state, customers: Customer[]) {
      state.customers = customers;
    },
    setCustomer(state, customer: Customer) {
      state.customer = customer;
    },
    setCustomerPreferences(state, customerPreferences: Setting<CustomerPreferences>) {
      state.customerPreferences = customerPreferences;
    },
    setCustomerPreferencesAppTitleAndAppTheme(state, customerPreferences: Setting<CustomerPreferences>) {
      if (state.customer?.id === customerPreferences.customerId) {
        if (state.customerPreferences) {
          state.customerPreferences.content = customerPreferences.content;
        } else {
          state.customerPreferences = {
            content: customerPreferences.content,
            customerId: state.customer.id,
            type: SettingType.CUSTOMER_PREFERENCES,
          };
        }
      }
    },
  },
  getters: {
    getPreferredTitle(state: CustomerState): string | undefined {
      return state.customerPreferences?.content?.appTitle;
    },
    getPreferences(state: CustomerState): Setting<CustomerPreferences> | undefined {
      return state.customerPreferences?.customerId === state.customer?.id ? state.customerPreferences : undefined;
    },
    getPreferredTheme(state: CustomerState): AppThemeType | undefined {
      return state.customerPreferences?.content?.appTheme;
    },
  },
};
