import {
  PublicClientApplication,
  AuthenticationResult,
  Configuration,
  LogLevel,
  AccountInfo,
  InteractionRequiredAuthError,
  RedirectRequest,
  EndSessionRequest,
} from '@azure/msal-browser';
import { MsalOptions } from '@/core/auth/models/MsalOptions';
import getLogger from '@/shared/logger/logger';
import { authScopesDefault } from '@/core/auth/models/AuthScope';
import { PATHS } from '@/ui/router/routes';

const LOG = getLogger('MsalAuthService');

const forgottenPasswordError = 'AADB2C90118';

const options: MsalOptions = {
  clientId: process.env.VUE_APP_MSAL_CLIENT_ID,
  loginAuthority: process.env.VUE_APP_MSAL_LOGIN_AUTHORITY,
  passwordAuthority: process.env.VUE_APP_MSAL_PASSWORD_RESET_AUTHORITY,
  knownAuthority: process.env.VUE_APP_MSAL_KNOWN_AUTHORITY,
  b2cScope: [process.env.VUE_APP_MSAL_B2C_SCOPE],
};

/**
 * Configuration class for @azure/msal-browser:
 * https://azuread.github.io/microsoft-authentication-library-for-js/ref/msal-browser/modules/_src_config_configuration_.html
 */
const MSAL_CONFIG: Configuration = {
  auth: {
    clientId: options.clientId,
    authority: options.loginAuthority,
    knownAuthorities: [options.knownAuthority],
    postLogoutRedirectUri: PATHS.CAMPAIGNS,
  },
  system: {
    loggerOptions: {
      loggerCallback: (level: LogLevel, message: string, containsPii: boolean): void => {
        if (containsPii) {
          return;
        }
        switch (level) {
          case LogLevel.Error:
            LOG.error(message);
            return;
          case LogLevel.Info:
            LOG.info(message);
            return;
          case LogLevel.Verbose:
            LOG.debug(message);
            return;
          case LogLevel.Warning:
            LOG.warn(message);
            return;
        }
      },
      piiLoggingEnabled: false,
      logLevel: LogLevel.Warning,
    },
  },
};

/**
 * AuthModule for application - handles authentication in app.
 */
export class MsalAuthService {
  private myMSALObj: PublicClientApplication; // https://azuread.github.io/microsoft-authentication-library-for-js/ref/msal-browser/classes/_src_app_publicclientapplication_.publicclientapplication.html
  private account: AccountInfo | null | undefined; // https://azuread.github.io/microsoft-authentication-library-for-js/ref/msal-common/modules/_src_account_accountinfo_.html

  constructor() {
    this.myMSALObj = new PublicClientApplication(MSAL_CONFIG);
    this.account = null;
  }

  /**
   * Calls getAllAccounts, currently defaults to first account found in cache.
   *
   * https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-common/docs/Accounts.md
   */
  getAccount(): AccountInfo | undefined {
    const currentAccounts = this.myMSALObj.getAllAccounts();
    return currentAccounts.length > 0 ? currentAccounts[0] : undefined;
  }

  /**
   * Checks whether we are in the middle of a redirect and handles state accordingly. Only required for redirect flows.
   *
   * https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-browser/docs/initialization.md#redirect-apis
   */
  async loadAuthModule(): Promise<AuthenticationResult | null | undefined> {
    try {
      const response = await this.myMSALObj.handleRedirectPromise();
      if (response && response.account) {
        this.account = response.account;
        return response;
      } else {
        await this.login();
      }
    } catch (error) {
      if (error instanceof Error && error.message && error.message.indexOf(forgottenPasswordError) > -1) {
        try {
          await this.myMSALObj.loginRedirect({
            scopes: authScopesDefault.concat(process.env.VUE_APP_MSAL_B2C_SCOPE),
            authority: options.passwordAuthority,
          });
        } catch (passwordResetError) {
          LOG.error(passwordResetError);
        }
      } else {
        LOG.error(error);
      }
    }
  }

  /**
   * Calls loginRedirect.
   */
  async login(): Promise<void> {
    const loginRequest: RedirectRequest = {
      scopes: authScopesDefault.concat(process.env.VUE_APP_MSAL_B2C_SCOPE),
      redirectStartPage: window.location.href,
    };
    await this.myMSALObj.loginRedirect(loginRequest).catch((error) => LOG.error(error));
  }

  /**
   * Logs out of current account.
   */
  logout(): void {
    const logOutRequest: EndSessionRequest = {
      account: this.account ? this.account : this.getAccount(),
    };
    this.myMSALObj.logoutRedirect(logOutRequest);
  }

  /**
   * Gets the token silently, or falls back to interactive redirect.
   */
  async acquireToken() {
    const silentRequest = {
      account: this.account ? this.account : this.getAccount(),
      scopes: authScopesDefault.concat(process.env.VUE_APP_MSAL_B2C_SCOPE),
      forceRefresh: false,
    };
    try {
      const response = await this.myMSALObj.acquireTokenSilent(silentRequest);
      return response.accessToken;
    } catch (error) {
      if (error instanceof InteractionRequiredAuthError) {
        return this.myMSALObj.acquireTokenRedirect(silentRequest).catch((error) => LOG.error(error));
      }
      return false;
    }
  }
}
