import type { Tag } from '@/core/tags/models/Tag';
import i18n from '@/i18n';
import _Vue from 'vue';
import type { LanguageObject } from '@/core/campaign/models/LanguageObject';
import { Languages } from '@/core/admin/constants/Languages.const';

const executorLogic = (tag: Tag): string | undefined => {
  if (!tag || !tag.value) {
    return;
  }
  const locale: keyof LanguageObject = i18n.i18next.language;

  // DESIRED_LANGUAGE, DE, FR, IT, EN.
  const sortedFilteredItems = [
    tag.value[locale],
    tag.value[Languages.DE],
    tag.value[Languages.FR],
    tag.value[Languages.IT],
    tag.value[Languages.EN],
  ].filter(Boolean);
  return sortedFilteredItems.length > 0 ? sortedFilteredItems[0] : undefined;
};

export const TagValue = {
  install(Vue: typeof _Vue) {
    Vue.prototype.$tagValue = executorLogic;
  },
};

declare module 'vue/types/vue' {
  interface Vue {
    // eslint-disable-next-line @typescript-eslint/ban-types
    $tagValue: Function;
  }
}
