














































import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
import LabelChip from '@/ui/components/atoms/groupingItems/LabelChip.vue';
import getLogger from '@/shared/logger/logger';
import ButtonWithText from '@/ui/components/atoms/buttons/ButtonWithText.vue';
import type { LanguageObject } from '@/core/campaign/models/LanguageObject';
import { FILTER_TYPE } from '@/ui/constant/date.const';
import { formatDate } from '@/ui/util/date';
import i18n from '@/i18n';
import { cloneDeep as _cloneDeep } from 'lodash';

/* eslint-disable-next-line @typescript-eslint/no-unused-vars */
const LOG = getLogger('DateFilter');

@Component({
  name: 'DateFilter',
  components: { LabelChip, ButtonWithText },
  computed: {},
})
export default class DateFilter extends Vue {
  @Prop({ default: true }) isClosable?: boolean;
  @Prop({ default: FILTER_TYPE.DATE }) dateType?: string;
  @Prop() selectedDates: string[] | undefined;
  private readonly locale: keyof LanguageObject = i18n.i18next.language;
  private showMenu = false;
  private dateRange: string[] = [];
  private temporaryDateRange: string[] = [];
  private showDateRange = true;

  mounted() {
    this.prepareSelectedDates();
  }

  @Watch('selectedDates')
  onReset(val: string[]) {
    if (!val || val.length === 0) {
      this.resetFilter();
    }
  }

  get hasTempAtLeastOneDate() {
    return this.temporaryDateRange.length > 0;
  }

  get hasTempTwoDates() {
    return this.temporaryDateRange.length === 2;
  }

  get hasNoValidTempDates() {
    return this.dateType === FILTER_TYPE.DATE ? !this.hasTempTwoDates : !this.hasTempAtLeastOneDate;
  }

  get hasTwoDates() {
    return this.dateRange.length === 2;
  }

  get datesString() {
    return this.hasTwoDates
      ? formatDate(this.dateRange[0], this.dateType) + ' - ' + formatDate(this.dateRange[1], this.dateType)
      : '';
  }

  prepareSelectedDates() {
    if (this.selectedDates) {
      this.temporaryDateRange = this.selectedDates;
      this.setDates(this.selectedDates);
    }
  }

  setDates(dates: string[]) {
    if (dates?.[0]) this.$set(this.dateRange, 0, dates[0]);
    if (dates?.[1]) this.$set(this.dateRange, 1, dates[1]);
  }

  updateParent() {
    this.dateRange = _cloneDeep(this.temporaryDateRange);
    this.showMenu = false;
    if (this.dateRange?.length === 1) {
      this.$set(this.dateRange, 1, this.dateRange[0]);
    }
    this.dateRange.sort();
    return this.dateRange?.length > 0 ? this.$emit('updated', this.dateRange) : this.$emit('updated', []);
  }

  removeDate() {
    this.resetFilter();
    this.updateParent();
  }

  resetFilter() {
    this.dateRange.splice(0, this.dateRange.length);
    this.temporaryDateRange.splice(0, this.temporaryDateRange.length);
  }
}
