import { NO_CONTENT } from '@/ui/constant/content.constant';
import { format, isValid, parseISO } from 'date-fns';

export function formatIsoTimestampToLocalizedTimestamp(timestamp: string): string {
  const parsed = parseISO(timestamp);
  if (isValid(parsed)) {
    return format(parsed, 'dd.MM.yyyy HH:mm');
  }
  return NO_CONTENT;
}

export function formatIsoTimestampToLocalizedShortDate(timestamp: string): string {
  const parsed = parseISO(timestamp);
  if (isValid(parsed)) {
    return format(parsed, 'dd.MM.yyyy');
  }
  return NO_CONTENT;
}
