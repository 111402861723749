import Vue from 'vue';
import VueI18Next from '@panter/vue-i18next';
import i18next from 'i18next';
import { language } from '@/ui/assets/locales/translations.json';

Vue.use(VueI18Next);

i18next.init({
  lng: 'de-CH',
  fallbackLng: 'de-CH',
  resources: {
    'de-CH': {
      translation: language.de,
    },
  },
});
const i18n = new VueI18Next(i18next);

export default i18n;
