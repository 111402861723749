import * as log from 'loglevel';
import * as lpre from 'loglevel-plugin-prefix';

lpre.reg(log);

if (process.env.NODE_ENV !== 'production') {
  log.setLevel(log.levels.DEBUG, false);
} else {
  log.setLevel(process.env.VUE_APP_LOG_LEVEL || log.levels.ERROR, false);
}

lpre.apply(log, {
  template: '[%t] %l (%n): ',
});

export default log.getLogger;
