










import { Component, Mixins } from 'vue-property-decorator';
import ButtonWithText from '@/ui/components/atoms/buttons/ButtonWithText.vue';
import getLogger from '@/shared/logger/logger';
import { loginUserUseCase } from '@/core/auth/usecases/loginUserUseCase';
import { loginRedirectUseCase } from '@/core/auth/usecases/loginRedirectUseCase';
import { getCustomerUseCase } from '@/core/customer/usecases/getCustomerUseCase';
import { NavigationMixin } from '@/ui/mixins/NavigationMixin';
import { USER_ROLE } from '@/core/user/enums/UserRole';
import { Loader } from '@/ui/util/decorators/loader';
import { Route } from 'vue-router';
import { ROUTE_CUSTOMERS } from '@/ui/router/routes';
import { ROUTE_CAMPAIGNS } from '@/ui/router/campaign.routes';

const LOG = getLogger('LoginView');

@Component({
  name: 'LoginView',
  components: { ButtonWithText },
})
export default class LoginView extends Mixins(NavigationMixin) {
  get userIsAuthenticated(): boolean {
    return this.$store.state.userStore.currentUserAccount;
  }

  get hasFetchedUserAuthorization(): boolean {
    return this.$store.state.userStore.currentUser;
  }

  get currentUser() {
    return this.$store.state.userStore.currentUser;
  }

  get isSuperAdmin(): boolean {
    return this.currentUser?.role === USER_ROLE.SUPER_ADMIN;
  }

  async created() {
    await this.doUserLogin();
  }

  @Loader
  private async doUserLogin(): Promise<void | Route> {
    await loginRedirectUseCase();
    if (this.userIsAuthenticated) {
      let targetPath = this.$route.query.redirect
        ? (this.$route.query.redirect as string)
        : this.isSuperAdmin
        ? ROUTE_CUSTOMERS.path
        : ROUTE_CAMPAIGNS.path;
      return this.hasFetchedUserAuthorization
        ? await this.setCustomerAndContinue(targetPath)
        : this.handleRoutingError(targetPath, 'User is missing authorization');
    } else {
      LOG.warn('Not authenticated, retry login');
      await loginUserUseCase();
    }
  }

  async setCustomerAndContinue(targetPath: string) {
    await getCustomerUseCase(this.currentUser?.id);
    await this.$router.push({ path: targetPath }).catch((e) => LOG.error(`Routing to ${targetPath} failed: ${e}`));
  }
}
