import { inject, injectable } from 'tsyringe';
import { HttpService } from '@/infrastructure/http/HttpService';
import { UserService } from '@/core/user/services/UserService';
import type { CurrentUser } from '@/core/user/models/CurrentUser';
import { GenericResponse } from '@/infrastructure/models/Response';

@injectable()
export class HttpUserService implements UserService {
  constructor(
    @inject(HttpService)
    private readonly httpService: HttpService,
  ) {}

  async getUserDetails(guid: string): Promise<CurrentUser> {
    const response = await this.httpService.get<GenericResponse<CurrentUser>>(`/user/${guid}/me`);
    return response?.result?.data;
  }
}
