import { container } from 'tsyringe';
import { StatisticsService } from '@/core/statistics/services/StatisticsService';
import type { CurrentUser } from '@/core/user/models/CurrentUser';
import { BaseStatistic } from '@/core/statistics/models/Statistics';
import getLogger from '@/shared/logger/logger';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const LOG = getLogger('mapCampaignMeasurementsDataToStatistics');

export const getCampaignStatisticsUseCase = async (
  currentUser: CurrentUser,
  campaignId?: string,
  startDate?: string,
  endDate?: string,
  zoneIds?: string[],
): Promise<BaseStatistic> => {
  const statisticsService = container.resolve<StatisticsService>('StatisticsService');
  const campaignMeasurements = await statisticsService.getCampaignStatistic(
    currentUser,
    campaignId,
    startDate,
    endDate,
    zoneIds,
  );
  return campaignMeasurements;
};
