import { Module } from 'vuex';
import { RootState } from '@/ui/store';
import getLogger from '@/shared/logger/logger';
import { timing } from '@/ui/constant/timing.constant';
import { getTranslationIdentifierFromStatus } from '@/ui/util/alertHelper';

const LOG = getLogger('AlertStore');

export const AlertStoreGetters = {
  IS_ALERT_SHOWN: 'alertStore/isAlertShown',
  MESSAGE: 'alertStore/message',
  TYPE: 'alertStore/type',
  IS_CUSTOM_MESSAGE: 'alertStore/isCustomMessage',
};

export interface SetAlertPayload {
  message: string;
  isCustomMessage?: boolean;
  type: AlertType;
}

export interface SetHttpAlertPayload {
  statusCode: number;
  type: AlertType;
}

export const AlertStoreActions = {
  TRIGGER_ALERT: 'alertStore/triggerAlert',
  TRIGGER_HTTP_ALERT: 'alertStore/triggerHttpAlert',
};

export const AlertStoreMutations = {
  RESET_ALERT: 'alertStore/resetAlert',
  SET_IS_LOADING: 'alertStore/setIsLoading',
};

export enum AlertType {
  NOT_SET = 'NOT_SET',
  SUCCESS = 'SUCCESS',
  WARNING = 'WARNING',
  ERROR = 'ERROR',
}

export interface AlertState {
  message: string;
  isCustomMessage?: boolean;
  showAlert: boolean;
  type: AlertType;
  isLoading: boolean;
  isLoadingCounter: number;
}

export const alertStore: Module<AlertState, RootState> = {
  namespaced: true,
  state: {
    message: '',
    isCustomMessage: false,
    showAlert: false,
    type: AlertType.NOT_SET,
    isLoading: false,
    isLoadingCounter: 0,
  },
  mutations: {
    resetAlert(state: AlertState) {
      if (state.showAlert) {
        state.showAlert = false;
        state.message = '';
        state.isCustomMessage = false;
        state.type = AlertType.NOT_SET;
      }
    },
    setIsLoading(state: AlertState, value: boolean) {
      if (value) {
        state.isLoadingCounter += 1;
      } else {
        state.isLoadingCounter > 0 ? (state.isLoadingCounter -= 1) : (state.isLoadingCounter = 0);
      }
      state.isLoading = !!state.isLoadingCounter;
    },
  },
  actions: {
    triggerAlert(context, alertPayload: SetAlertPayload) {
      if (!context.state.showAlert || alertPayload.type === AlertType.ERROR) {
        LOG.debug(`${alertPayload.type}-alert triggered with message ${alertPayload.message}`);
        context.state.message = alertPayload.message;
        context.state.isCustomMessage = alertPayload.isCustomMessage;
        context.state.type = alertPayload.type;
        context.state.showAlert = true;
        setTimeout(() => {
          context.commit('resetAlert');
        }, timing.SUCCESS_TIMEOUT.MILLISECONDS);
      }
    },
    triggerHttpAlert(context, httpAlertPayload: SetHttpAlertPayload) {
      const translationIdentifier = getTranslationIdentifierFromStatus(httpAlertPayload.statusCode);
      const alertPayload: SetAlertPayload = {
        message: translationIdentifier,
        type: httpAlertPayload.type,
      };
      context.dispatch('triggerAlert', alertPayload);
    },
  },
  getters: {
    isAlertShown(state: AlertState) {
      return state.showAlert;
    },
    message(state: AlertState) {
      return state.message;
    },
    type(state: AlertState) {
      return state.type;
    },
    isCustomMessage(state: AlertState) {
      return state.isCustomMessage;
    },
  },
};
