


























import { Component, Prop, Vue } from 'vue-property-decorator';

export interface EventHandlers {
  click?: () => void;
}

@Component
export default class ButtonWithTextAndIcon extends Vue {
  @Prop({ type: Object, default: () => new Object() }) eventHandlers!: EventHandlers;
  @Prop({ default: null }) translationIdentifier?: string;
  @Prop({ default: null }) icon?: string;
  @Prop({ default: undefined }) name?: string;
  @Prop({ default: false }) buttonIsAnIcon!: boolean;
  @Prop({ default: true }) iconIsLeftAligned!: boolean;
  @Prop({ default: undefined }) iconColor!: string;
  @Prop({ default: undefined }) iconSize!: number;

  private emitClick() {
    this.$emit('click');
  }
}
