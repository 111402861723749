



























































































import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
import getLogger from '@/shared/logger/logger';
import type { LanguageObject } from '@/core/campaign/models/LanguageObject';
import i18n from '@/i18n';
import LabelChip from '@/ui/components/atoms/groupingItems/LabelChip.vue';
import { CampaignMedia } from '@/core/campaign/models/CampaignMedia';
import { groupBy as _groupBy } from 'lodash';
import ButtonWithText from '@/ui/components/atoms/buttons/ButtonWithText.vue';
import type { Tag } from '@/core/tags/models/Tag';
import OverflowChip from '@/ui/components/atoms/groupingItems/OverflowChip.vue';
import { INPUT } from '@/ui/constant/input.const';

/* eslint-disable-next-line @typescript-eslint/no-unused-vars */
const LOG = getLogger('SubjectFilter');

@Component({
  name: 'SubjectFilter',
  components: { ButtonWithText, LabelChip, OverflowChip },
  computed: {},
})
export default class SubjectFilter extends Vue {
  @Prop() private items!: Tag[];
  @Prop({ default: undefined }) campaignMedia: CampaignMedia[] | undefined;
  @Prop({ default: false }) isDetailFilter: boolean | undefined;
  @Prop({ default: false }) showCheckbox: boolean | undefined;
  @Prop() selectedFilter!: Tag[];
  @Prop({ default: 200 }) searchDebounce!: number;
  private showMenu = false;
  private temporarySelected: Tag[] = [];
  private search = '';
  private readonly locale: keyof LanguageObject = i18n.i18next.language;
  private numberOfFilterTags = 3;
  debounceTimeout: number | undefined;

  mounted() {
    this.temporarySelected = this.selectedFilter;
  }

  @Watch('selectedFilter')
  onReset(val: string[]) {
    if (!val || val.length === 0) {
      this.resetFilter();
    }
  }

  @Watch('searchInput')
  onSearch(val: string[]) {
    if (val && val.length >= INPUT.MIN_SUGGESTION_STRING_LENGTH) {
      if (this.debounceTimeout) {
        clearTimeout(this.debounceTimeout);
      }
      this.debounceTimeout = setTimeout(() => {
        this.$emit('search', val);
        clearTimeout(this.debounceTimeout);
        this.debounceTimeout = undefined;
      }, this.searchDebounce);
    } else {
      this.$emit('search', '');
    }
  }

  get searchInput() {
    return this.search.toLowerCase();
  }

  get groupedSubjects() {
    if (this.isDetailFilter) {
      const groupedSubjects = _groupBy(this.campaignMedia, `subject.value[${this.locale}]`);
      delete groupedSubjects['undefined'];
      return groupedSubjects;
    } else {
      const allSubjects = this.items ? this.items : [];
      return _groupBy(allSubjects, `value[${this.locale}]`);
    }
  }

  get groupedSubjectsIsEmpty() {
    return Object.keys(this.groupedSubjects).length === 0;
  }

  get predefinedItems() {
    const search = this.search.toLowerCase();
    if (!search || search.length < INPUT.MIN_SUGGESTION_STRING_LENGTH) return [];
    return this.items.filter((item) => {
      const text = this.$tagValue(item)?.toLowerCase();

      return text.indexOf(search) > -1;
    });
  }

  removeSubject(index: number) {
    if (this.temporarySelected?.length > 0) {
      this.temporarySelected.splice(index, 1);
      this.updateParent(this.temporarySelected);
    }
  }

  addSelectedItem(item: Tag) {
    this.temporarySelected.push(item);
    this.search = '';
  }

  updateParent(updatedFilters: Tag[]) {
    this.$emit('updated', updatedFilters);
    this.showMenu = false;
  }

  resetFilter() {
    this.temporarySelected = [];
  }

  getSubject(subjects: CampaignMedia[]) {
    return subjects?.[0].subject;
  }
}
