


















import { Component, Prop, Vue } from 'vue-property-decorator';

@Component
export default class Button extends Vue {
  @Prop({ default: null }) text?: string;
  @Prop({ default: null }) colorBlue?: boolean;
  @Prop({ default: false }) disabled?: boolean;
  @Prop({ default: true }) outlined!: boolean;
  @Prop({ default: true }) tile!: boolean;
  @Prop({ default: true }) depressed!: boolean;
  @Prop({ default: false }) isText!: boolean;
  @Prop({ default: undefined }) iconColor!: string;
  @Prop({ default: false }) isRounded!: boolean;
  @Prop({ default: false }) dark!: boolean;
  @Prop({ type: Boolean, default: false }) loading!: boolean;

  emitClick() {
    this.$emit('click');
  }
}
