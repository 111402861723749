import { IdTokenClaims } from '@azure/msal-common';
import { container } from 'tsyringe';
import { AuthService } from '@/core/auth/services/AuthService';
import { UserService } from '@/core/user/services/UserService';
import { UserStoreMutations } from '@/ui/stores/UserStore';
import store from '@/ui/store';
import getLogger from '@/shared/logger/logger';
import { CustomerService } from '@/core/customer/services/CustomerService';
import { CustomerPreferences, Setting } from '@/core/shared/model/Setting';
import { CustomerStoreMutations } from '@/ui/stores/CustomerStore';
import type { CurrentUser } from '@/core/user/models/CurrentUser';

const LOG = getLogger('LoginRedirectUseCase');

export const loginRedirectUseCase = async (): Promise<void> => {
  const authService = container.resolve<AuthService>('MsalAuthService');
  const userService = container.resolve<UserService>('UserService');
  const customerService = container.resolve<CustomerService>('CustomerService');

  const response = await authService.loadAuthModule();
  const account = response !== null ? response?.account : authService.getAccount();
  LOG.info(`USER ${UserStoreMutations.UPDATE_CURRENT_USER_ACCOUNT}`);
  store.commit(UserStoreMutations.UPDATE_CURRENT_USER_ACCOUNT, account);

  const userId = (account?.idTokenClaims as IdTokenClaims).oid as string;

  const loadUsersDefaultCustomerPreferences = async (currentUser: CurrentUser) => {
    const customerPreferences: Setting<CustomerPreferences> = await customerService.getCustomerPreferences(
      currentUser.customerId,
      userId,
    );
    store.commit(CustomerStoreMutations.SET_CUSTOMER_PREFERENCES, customerPreferences);
  };

  try {
    if (userId && !store.state.userStore.currentUser) {
      const currentUser = await userService.getUserDetails(userId);
      if (currentUser.id === userId) {
        store.commit(UserStoreMutations.UPDATE_CURRENT_USER, currentUser);
        await loadUsersDefaultCustomerPreferences(currentUser);
      } else {
        throw new Error(`user ids don't match!`);
      }
    } else {
      throw new Error(`user id not available`);
    }
  } catch (e) {
    LOG.error(`Could not retrieve user correctly. logging out due to ${e}`);
    await authService.logout();
  }
};
