import type { CurrentUser } from '@/core/user/models/CurrentUser';
import { container } from 'tsyringe';
import { FindTagsDTO } from '@/core/tags/DTO/FindTagsDTO';
import { TagsService } from '@/core/tags/services/TagsService';
import type { Tag } from '@/core/tags/models/Tag';

export const findTagsUseCase = async (data: FindTagsDTO, user: CurrentUser): Promise<Array<Tag>> => {
  const tagsService = container.resolve<TagsService>('TagsService');
  return await tagsService.findTags(data, user);
};
