import { Module } from 'vuex';
import { RootState } from '@/ui/store';
import type { CurrentUser } from '@/core/user/models/CurrentUser';
import { AccountInfo } from '@azure/msal-browser';
import { USER_PERMISSION } from '@/core/user/enums/UserPermission';
import { USER_ROLE } from '@/core/user/enums/UserRole';

export const UserStoreMutations = {
  UPDATE_CURRENT_USER: 'userStore/updateCurrentUser',
  UPDATE_CURRENT_USER_ACCOUNT: 'userStore/updateCurrentUserAccount',
  UPDATE_CURRENT_USER_CUSTOMER_ID: 'userStore/updateCurrentCustomerId', // only needed for superadmins
  UPDATE_PREVIOUS_CUSTOMER_ID: 'userStore/updatePreviousCustomerId', // only needed for superadmins
};

export const UserStoreGetters = {
  USER_CAN_DOWNLOAD: 'userStore/userCanDownload',
};

export interface UserState {
  currentUser: CurrentUser | undefined;
  currentUserAccount: AccountInfo | undefined;
  previousCustomerId: string | undefined;
}

export const userStore: Module<UserState, RootState> = {
  namespaced: true,
  state: {
    currentUser: undefined,
    currentUserAccount: undefined,
    previousCustomerId: undefined,
  },
  mutations: {
    updateCurrentUser(state, currentUser: CurrentUser) {
      state.currentUser = currentUser;
    },
    updateCurrentUserAccount(state, currentUserAccount: AccountInfo) {
      state.currentUserAccount = currentUserAccount;
    },
    updateCurrentCustomerId(state, currentCustomerId: string) {
      if (state.currentUser) {
        state.currentUser.customerId = currentCustomerId;
      }
    },
    updatePreviousCustomerId(state, previousCustomerId: string) {
      state.previousCustomerId = previousCustomerId;
    },
  },
  getters: {
    userCanDownload: (state: UserState): boolean =>
      state.currentUser?.role === USER_ROLE.ADMIN ||
      state.currentUser?.role === USER_ROLE.SUPER_ADMIN ||
      !!state.currentUser?.permissions.includes(USER_PERMISSION.DOWNLOAD),
  },
};
