import { MediaService } from '@/core/media/services/MediaService';
import { inject, injectable } from 'tsyringe';
import { HttpService } from '@/infrastructure/http/HttpService';
import { API_PATH } from '@/infrastructure/enum/ApiPath';
import { GenericResponse, CreateCampaignMediumResponseDTO } from '@/infrastructure/models/Response';
import type { CurrentUser } from '@/core/user/models/CurrentUser';

import axios, { AxiosResponse } from 'axios';

@injectable()
export class HttpMediaService implements MediaService {
  constructor(
    @inject(HttpService)
    private readonly httpService: HttpService,
  ) {}

  async createMediaFileUploadLink(
    file: File,
    user: CurrentUser,
    campaignId: string,
  ): Promise<CreateCampaignMediumResponseDTO> {
    const endpoint = `${API_PATH.CUSTOMER}/${user.customerId}/${user.id}/${API_PATH.CAMPAIGN}/${campaignId}/${API_PATH.MEDIUM}`;
    const response = await this.httpService.post<GenericResponse<CreateCampaignMediumResponseDTO>>(endpoint, {
      originalFileName: file.name,
      contentType: file.type,
    });
    return response?.result?.data;
  }

  async uploadMedium(endpoint: string, file: File): Promise<void> {
    await axios.put(endpoint, file, {
      headers: {
        'Content-Type': file.type,
        'x-ms-blob-type': 'BlockBlob',
      },
    });
  }

  async getMediumBlob(source: string): Promise<AxiosResponse<Blob>> {
    return await axios.get<Blob>(source, { responseType: 'arraybuffer' });
  }
}
